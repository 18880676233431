<template lang='pug'>
    #Contact.main-page.detail-page
        v-overlay(v-model='loading' z-index='99' opacity='.6')
            v-progress-circular(indeterminate color='primary')
        div.height_100(v-show='!loading')
            v-breadcrumbs.pa-0.px-3.pb-2.ellipsis(:items='breadcrumbs')
            v-row#page-head.d-flex.align-center.my-0
                v-col(cols='4' lg='6')
                    .d-flex.align-center
                        v-hover
                            template(v-slot:default="{ hover }")
                                v-list-item-avatar.ma-0.mr-3(color='aliceBlue' @click)
                                    v-icon.mx-auto(v-if="contactsDetail.image === null") {{ contactsDetail.last_name }}
                                    img(v-else :src='contactsDetail.image' :alt='contactsDetail.image')
                                    v-fade-transition.d-flex.align-center.justify-center
                                        v-overlay.d-flex.align-center.justify-center(v-if="hover" absolute color='black')
                                            v-file-input.no-border(v-show="false" v-model="contactImage" ref="uploader" @change="uploadimage" accept="image/*")
                                            v-icon(@click="changephoto" size='25' ) mdi-camera
                        h1.my-0.ellipsis(v-if='contactsDetail.first_name != null' :title='contactsDetail.last_name+" "+contactsDetail.first_name') {{ contactsDetail.last_name+" "+contactsDetail.first_name }}
                        h1.my-0.ellipsis(v-else) {{ contactsDetail.last_name }}
                        followBtn(:follow='isFollow' :page='"Contact"' :target_id='target_id' @emitChangeFollow='onEmitChangeFollow')


                v-col(cols='8' lg='6')
                    v-row.justify-end(no-gutters='no-gutters')
                        v-col.ml-2(cols='auto')
                            .btn-group
                                v-btn(@click='onDelete' width='100%' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.DELETE')}}
                        v-col.ml-2(cols='auto')
                            .btn-group
                                v-btn(@click='onChangeOwn(contactsDetail)' width='100%' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.CHANGE_OWNER')}}
                        v-col.ml-2(cols='auto')
                            v-btn(@click='showAddDealDialog' width='100%' height='32' color='orange' depressed dark)
                                v-icon.mr-1(size='14') icon-add
                                span {{$t('GENERAL.ADD_DEAL')}}
                        v-col.ml-2(cols='auto')
                            v-btn(@click='showAddCaseDialog' width='110' height='32' color='green' depressed dark)
                                v-icon.mr-1(size='14') icon-add
                                span {{$t('GENERAL.ADD_Case')}}
                        v-col.pr-md-0.pl-md-1.d-flex(cols='auto')
                            refreshBtn(@EmitRefresh='onEmitRefresh')
            #page-inner.pa-3.pt-0
                v-card.card-preface.card-shadow.px-0.py-6(flat)
                    v-row
                        v-col.px-2.px-md-4.py-0(cols='6' md='2')
                            v-list-item.d-flex.align-center.px-0
                                v-list-item-icon.mt-3.mb-0.mr-2
                                    v-icon(size='28') mdi-briefcase
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('CONTACT_DETAIL.TITLE')}}
                                    .f-text-highlight.mb-0.ellipsis {{ contactsDetail.title }}
                        v-col.px-2.px-md-4.py-0.border-left(cols='6' md='2')
                            v-list-item.d-flex.align-center.px-0
                                v-list-item-icon.mt-3.mb-0.mr-2
                                    v-icon(size='28') mdi-domain
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('CONTACT_DETAIL.ORG_NAME')}}
                                    a.t-primary.f-text-highlight.mb-0.ellipsis(v-if="head_org != ''" :href="'/Account/'+head_org.id" color='black' text) {{ head_org.name }}
                        v-col.px-2.px-md-4.py-0.border-left(cols='6' md='2')
                            v-list-item.d-flex.align-center.px-0
                                v-list-item-icon.mt-3.mb-0.mr-2
                                    v-icon(size='28') mdi-phone
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('CONTACT_DETAIL.MOBILE_PHONE')}}
                                    a.f-text-highlight.mb-0.ellipsis(:href='onCall(contactsDetail.mobile_phone)' target='_blank') {{ contactsDetail.mobile_phone }}
                        v-col.px-2.px-md-4.py-0.border-left(cols='6' md='3')
                            v-list-item.d-flex.align-center.px-0
                                v-list-item-icon.mt-3.mb-0.mr-2
                                    v-icon(size='28') mdi-email
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('GENERAL.MAIL')}}
                                    a.f-text-highlight.mb-0.ellipsis(:href='onMail(contactsDetail.email)' target='_blank') {{ contactsDetail.email }}
                        v-col.px-2.px-md-4.py-0.border-left(cols='12' md='3')
                            v-list-item.d-flex.align-center.pa-0
                                v-list-item-avatar.ma-0.mr-2(color='aliceBlue')
                                    span.mx-auto(v-if="!isPhoto") {{ changeOwnData.last_name }}
                                    img(v-else :src='changeOwnData.usersetting.photo' :alt='changeOwnData.last_name')
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('CONTACT_DETAIL.OWNER')}}
                                    a.t-primary.mb-0.f-text-highlight.ellipsis(:href='onMail(changeOwnData.email)' v-if='changeOwnData.first_name != null') {{ changeOwnData.last_name + " " +changeOwnData.first_name }}
                                    a.t-primary.mb-0.f-text-highlight.ellipsis(:href='onMail(changeOwnData.email)' v-else) {{ changeOwnData.last_name }}
                v-row.pinning-card.hidden-sm-only.hidden-xs-only(no-gutters v-for='(item,index) in alertNote' :key='index' :style='setPosition(index)' :class='pinning ? "": "d-none"')
                    v-col(cols='1')
                        v-icon.ml-2(color='white') mdi-firework
                    v-col(:cols='index === 0 ? "7" : "9" ').ellipsis
                        strong.ellipsis {{ item.content.replace( /(<([^>]+)>)/ig, '') }}
                    v-col(:cols='index === 0 ? "4" : "2" ').d-flex.justify-end
                        span.ml-3 {{ item.alertTime }} 
                        .mx-3.d-flex( v-if='index === 0')
                            v-btn.process-box-open(v-if='alertNote.length > 1' width='24' height='24' depressed fab outlined @click='folder(index)')
                                v-icon(size='14' v-if='getfolder === false') icon-arrow-top
                                v-icon(size='14' v-else) icon-arrow-btm
                            v-menu(bottom left)
                                template(v-slot:activator='{ on, attrs }')
                                    v-btn.ml-3(color='white' dark v-bind='attrs' v-on='on' icon dense min-height='20' height='20' width='20')
                                        v-icon(size='16')  icon-dot-3
                                v-list.pa-0
                                    v-list-item.px-0.text-center
                                        v-btn(@click='onPinning()' text='text')
                                            span.ml-1 {{$t('GENERAL.CLOSE')}}
                v-row.account-info.mt-5(no-gutters='no-gutters')
                    v-col(cols='12' md='8')
                        v-card.deal-tab-card(flat)
                            v-tabs.main-tabs(v-if='!loading' v-model='tab' background-color='transparent' show-arrows)
                                v-tabs-slider
                                v-tab(v-for='item in tabs' :key='item.key' :href='`#tab-${item.id}`' @click='getTabName(item.key)')
                                    | {{ item.name }}
                                // - Detail -
                                v-tab-item.pa-3(key='detail' value='tab-1' :transition='false' :reverse-transition='false')
                                    opptyCard.mb-4(:open-oppties='openOppties')
                                    caseCard.mt-7.mb-4(:open-cases='openCases' :open-amount='openCasesCount')
                                    v-row(no-gutters).mt-7.mb-4
                                        v-col.d-flex.align-center.justify-start(cols='6' )
                                            h3.d-flex.align-center
                                                .cube-icon.small.bg-primary.mr-2
                                                    v-icon(size='20' color='white') mdi-account
                                                span {{$t('CONTACT_DETAIL.CONTACT_INFORMATION')}}
                                    v-form(v-model='valid' ref='formContact')
                                        v-row(no-gutters)
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CONTACT_DETAIL.LAST_NAME')}}
                                                    v-text-field(v-model='contactsDetail.last_name' solo :readonly='isContactReadonly' ref='firstTextField' :rules="formRule.nameRules" ) 
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly && !loading_contactDetail' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly && !loading_contactDetail' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isContactReadonly ? "py-2" : " "')
                                                        label {{$t('CONTACT_DETAIL.OFFICE_PHONE')}}
                                                        v-text-field.ellipsis(v-if='!isContactReadonly' v-model='contactsDetail.office_phone' solo :rules="formRule.contentLenRule50")
                                                            template(v-slot:append)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly' @click='confirmEditDetail(true)') icon-check
                                                                v-icon(size='18' color="red" v-if='!isContactReadonly' @click='cancelEditDetail(1)') mdi-window-close 
                                                        .d-flex.justify-space-between.w-100.ellipsis(v-else)
                                                            a.t-primary.ellipsis(:href='onCall(contactsDetail.office_phone)') {{ contactsDetail.office_phone }}
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CONTACT_DETAIL.FIRST_NAME')}}
                                                    v-text-field(v-model='contactsDetail.first_name' solo :readonly='isContactReadonly' :rules="formRule.contentLenRule50" )
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly && !loading_contactDetail' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly && !loading_contactDetail' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                .d-flex.align-center.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isContactReadonly ? "py-2" : " "')
                                                        label {{$t('CONTACT_DETAIL.MOBILE_PHONE')}}
                                                        vue-tel-input-vuetify.w-100(v-if='!isContactReadonly' v-model="contactsDetail.mobile_phone" label=''  placeholder='' autocomplete='off' :defaultCountry='getLocation()' disabledFetchingCountry=true mode='international' @validate="onPhoneCheck($event)" @input="onPhoneInput" :rules="formRule.phoneRules")
                                                            template(v-slot:append)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly' @click='confirmEditDetail(true)') icon-check
                                                                v-icon(size='18' color="red" v-if='!isContactReadonly' @click='cancelEditDetail(1)') mdi-window-close  
                                                        .d-flex.justify-space-between.w-100.ellipsis(v-if='isContactReadonly')
                                                            a.t-primary.ellipsis( :href='onCall(contactsDetail.mobile_phone)') {{ contactsDetail.mobile_phone }}
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1    
                                                div.d-flex.align-center.justify-space-between.input-has-label.w-100
                                                    .d-inline-flex.align-center.w-100
                                                        label {{$t('CONTACT_DETAIL.BIRTHDAY')}}
                                                        v-text-field.w-100(v-if='isContactReadonly' v-model='contactsDetail.birthdate' solo :readonly='isContactReadonly')
                                                            template(v-slot:append)
                                                                v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                                v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                                        v-menu(v-if='!isContactReadonly' ref="menu" v-model="menu" :z-index='100' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                                                            template(v-slot:activator="{ on, attrs }")
                                                                v-text-field(v-model='contactsDetail.birthdate' solo :readonly='!isContactReadonly' v-bind="attrs" v-on="on" clearable)
                                                                    template(v-slot:append)
                                                                        v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly' @click='confirmEditDetail(true)') icon-check
                                                                        v-icon(size='18' color="red" v-if='!isContactReadonly' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-date-picker( v-model='contactsDetail.birthdate' @input='menu = false' no-title scrollable) 
                                            v-col.pr-md-4(cols='12' md='6').my-1    
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CONTACT_DETAIL.FAX')}}
                                                    v-text-field(v-model='contactsDetail.fax' solo :readonly='isContactReadonly' :rules="formRule.contentLenRule20")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly && !loading_contactDetail' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly && !loading_contactDetail' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1    
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isContactReadonly ? "py-2" : " "')
                                                        label {{$t('CONTACT_DETAIL.ADDRESS')}}
                                                        v-text-field(v-if='!isContactReadonly' v-model='contactsDetail.billing_address' solo )
                                                            template(v-slot:append)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly' @click='confirmEditDetail(true)') icon-check
                                                                v-icon(size='18' color="red" v-if='!isContactReadonly' @click='cancelEditDetail(1)') mdi-window-close 
                                                        .d-flex.justify-space-between.w-100.ellipsis(v-else)
                                                            a.t-primary.ellipsis(:href='onGoogleMap(contactsDetail.billing_address)') {{ contactsDetail.billing_address }}
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                            v-col.pr-md-4(cols='12' md='6').my-1    
                                                div.d-flex.align-center.justify-space-between.input-has-label
                                                    .d-inline-flex.align-center.w-100(:class='isContactReadonly ? "py-2" : " "')
                                                        label {{$t('GENERAL.MAIL')}}
                                                        v-text-field(v-if='!isContactReadonly' v-model='contactsDetail.email' solo :rules="formRule.emailRules" name="email")
                                                            template(v-slot:append)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly' @click='confirmEditDetail(true)') icon-check
                                                                v-icon(size='18' color="red" v-if='!isContactReadonly' @click='cancelEditDetail(1)') mdi-window-close 
                                                        .d-flex.justify-space-between.w-100.ellipsis(v-else)
                                                            a.t-primary.ellipsis(:href='onMail(contactsDetail.email)') {{ contactsDetail.email }}
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CONTACT_DETAIL.CITY')}}
                                                    v-text-field(v-model='contactsDetail.city' solo :readonly='isContactReadonly' :rules="formRule.contentLenRule50")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly && !loading_contactDetail' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly && !loading_contactDetail' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1    
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CONTACT_DETAIL.TITLE')}}
                                                    v-text-field(v-model='contactsDetail.title' solo :readonly='isContactReadonly' :rules="formRule.contentLenRule50")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly && !loading_contactDetail' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly && !loading_contactDetail' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CONTACT_DETAIL.COUNTRY')}}
                                                    v-text-field(v-model='contactsDetail.country' solo :readonly='isContactReadonly' :rules="formRule.contentLenRule20")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly && !loading_contactDetail' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly && !loading_contactDetail' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CONTACT_DETAIL.LEVEL')}}
                                                    v-select.no-border(v-model='contactsDetail.level' attach solo :readonly='isContactReadonly' :class='isContactReadonly ? "remove-arrow" : ""' :items='levelList' item-text='name' item-value='key' :menu-props='{closeOnClick:true}')
                                                        template(v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CONTACT_DETAIL.ZIP_CODE')}}
                                                    v-text-field(v-model='contactsDetail.zip_code' solo :readonly='isContactReadonly' :rules="formRule.contentLenRule10")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly && !loading_contactDetail' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly && !loading_contactDetail' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CONTACT_DETAIL.DEPARTMENT')}}
                                                    v-text-field(v-model='contactsDetail.department' solo :readonly='isContactReadonly' :rules="formRule.contentLenRule50")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly && !loading_contactDetail' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly && !loading_contactDetail' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CONTACT_DETAIL.PROVINCE')}}
                                                    v-text-field(v-model='contactsDetail.province' solo :readonly='isContactReadonly' :rules="formRule.contentLenRule50")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly && !loading_contactDetail' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly && !loading_contactDetail' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                div.d-flex.align-center.justify-space-between.input-has-label.long-field-name
                                                    .d-inline-flex.align-center.w-100.overflow-hidden(:class='isContactReadonly ? "py-2" : " "' )
                                                        label
                                                            v-icon.d-inline.mr-2(size='21' color='t-lightgary') mdi-facebook
                                                            label.d-inline facebook
                                                        v-text-field(v-if='!isContactReadonly' v-model='contactsDetail.facebook' solo :rules="formRule.fbRules" )
                                                            template(v-slot:append)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly' @click='confirmEditDetail(true)') icon-check
                                                                v-icon(size='18' color="red" v-if='!isContactReadonly' @click='cancelEditDetail(1)') mdi-window-close 
                                                        .d-flex.w-100.ellipsis(v-else)
                                                            a.t-primary.ellipsis(:href='contactsDetail.facebook') {{ contactsDetail.facebook }}
                                                            v-icon.ml-auto(size='18'  v-if='!loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                                            v-progress-circular.ml-auto(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                div.d-flex.align-center.justify-space-between.input-has-label.long-field-name
                                                    .d-inline-flex.align-center.w-100.overflow-hidden(:class='isContactReadonly ? "py-2" : " "')
                                                        label
                                                            v-icon.d-inline.mr-2(size='21' color='t-lightgary') mdi-linkedin
                                                            label.d-inline linkedin
                                                        v-text-field(v-if='!isContactReadonly' v-model='contactsDetail.linkedin' solo :rules="formRule.urlRules")
                                                            template(v-slot:append)
                                                                v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly' @click='confirmEditDetail(true)') icon-check
                                                                v-icon(size='18' color="red" v-if='!isContactReadonly' @click='cancelEditDetail(1)') mdi-window-close 
                                                        .d-flex.w-100.ellipsis(v-else)
                                                            a.ellipsis.t-primary( :href='contactsDetail.linkedin') {{ contactsDetail.linkedin }}
                                                            v-icon.ml-auto(size='18' v-if='!loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                                            v-progress-circular.ml-auto(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label.long-field-name
                                                    label
                                                        v-icon.d-inline.mr-2(size='21' color='t-lightgary') fab fa-line
                                                        label.d-inline line
                                                    v-text-field(v-model='contactsDetail.line_id' solo :readonly='isContactReadonly' :rules="formRule.contentLenRule50")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly && !loading_contactDetail' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly && !loading_contactDetail' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label.long-field-name
                                                    label
                                                        v-icon.d-inline.mr-2(size='21' color='t-lightgary') mdi-instagram
                                                        label.d-inline instagram
                                                    v-text-field(v-model='contactsDetail.instagram_id' solo :readonly='isContactReadonly' :rules="formRule.contentLenRule50")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly && !loading_contactDetail' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly && !loading_contactDetail' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CONTACT_DETAIL.RELATED_CAMPAIGN')}}
                                                    v-autocomplete.no-border(v-model='contactsDetail.primary_campaign_id' attach solo :readonly='isContactReadonly' :class='isContactReadonly ? "remove-arrow" : ""' :items='campaignList' item-text='name' item-value='id'  :menu-props='{closeOnClick:true}')
                                                        template(v-slot:append-outer)
                                                            v-icon.mr-1(size='18' color="green" v-if='!isContactReadonly' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-icon(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                                        template(v-slot:selection='data')
                                                            a.t-primary.ellipsis(:href="'/Campaign/'+ data.item.id" style='max-width: 250px;') {{ data.item.name }}
                                            v-col.pr-md-4(cols='12').my-1
                                                p.d-flex.align-center.input-has-label
                                                    label {{$t('CONTACT_DETAIL.DESCRIPTION')}}
                                                    v-textarea.textarea-style(v-model='contactsDetail.description' :readonly='isContactReadonly' solo no-resize)
                                                        template(v-slot:append)
                                                            v-icon.mr-1.ml-1(size='18' color="green" v-if='!isContactReadonly' @click='confirmEditDetail(true)') icon-check
                                                            v-icon(size='18' color="red" v-if='!isContactReadonly' @click='cancelEditDetail(1)') mdi-window-close  
                                                            v-icon.ml-1(size='18' v-if='isContactReadonly && !loading_contactDetail' @click='onEditDetail(1)') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_contactDetail' indeterminate color='primary')
                                    customField(v-if='!loading' :Data='customFieldData' DataClass = 'Contact' :Editable='true')
                                    div.mt-7.mb-4.d-flex.align-center.justify-space-between
                                        h3.d-flex.align-center
                                            .cube-icon.small.bg-purple.mr-2
                                                v-icon(size='20' color='white') mdi-domain
                                            span {{$t('CONTACT_DETAIL.COMPANY_INFORMATION')}}
                                        v-col.d-flex.align-center.justify-end.px-0
                                            v-btn(@click='showAddContactOrg' width='130' height='32' color='green' depressed dark)
                                                v-icon.mr-1(size='14') icon-add
                                                span {{$t('CONTACT_DETAIL.ADD_COMPANY_INFORMATION')}}
                                    v-row.sheet-box(v-if='contactsOrgs == 0').mb-4
                                        v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                h5.ma-0.t-black {{$t('CONTACT_DETAIL.NO_COMPANY_INFORMATION')}}
                                    v-form(v-model='valid' ref='formOrg')
                                        v-row.mb-6(no-gutters v-for='org, index in contactsOrgs' :key='index')
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                div.d-flex.align-center.justify-space-between.input-has-label(:class='org == "" && isCompanyReadonly[index] ?"py-2" : "" ')
                                                    .d-inline-flex.align-center.w-100
                                                        label {{$t('CONTACT_DETAIL.COMPANY_NAME')}}
                                                        v-combobox(v-if='!isCompanyReadonly[index]' v-model="contactsOrgs[index].organization" solo :items="organizationList" item-text="name" item-value="uqNumber" :search-input.sync="search" :loading="comboBoxIsLoading" :rules="formRule.companyRule")
                                                            template(v-slot:append-outer)
                                                                .mr-1(v-if="isNew")
                                                                    v-icon(color="red", size="25") mdi-new-box
                                                                v-icon.mr-1(size='18' color="green" v-if='!showCompanyEditBtn[index] && !loading_OrgDetail[index]' @click='confirmEditContactOrg(index, contactsOrgs[index])') icon-check
                                                                v-icon(size='18' color="red" v-if='!showCompanyEditBtn[index] && !loading_OrgDetail[index]' @click='cancelEditDetail(2, index)') mdi-window-close  
                                                                v-progress-circular(size='18' v-if='loading_OrgDetail[index]' indeterminate color='primary')
                                                            template(v-slot:item="data")
                                                                div.ellipsis(v-if="data.item.id == 0")
                                                                    span {{ data.item.name }}
                                                                    v-icon.mr-1(color="red", size="25") mdi-new-box
                                                                div.ellipsis(v-else)
                                                                    span {{ data.item.name }}
                                                        .d-flex.justify-space-between.w-100.ellipsis(v-if="isCompanyReadonly[index]")
                                                            a.t-primary.py-2.ellipsis( :href="'/Account/' + contactsOrgs[index].organization.id" :title='contactsOrgs[index].organization.name') {{ contactsOrgs[index].organization.name }}
                                                            div
                                                                v-icon.mr-1(size='18' v-if='!isEdit_2[index]' @click='onEditDetail(2, index)') icon-edit
                                                                v-icon(size='18' v-if='!isEdit_2[index]' @click='onDeleteOrg(index)') icon-remove
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label(:class='org == "" ?"py-2" : "" ')
                                                    label {{$t('CONTACT_DETAIL.JURISTIC_PERSON_NAME')}}
                                                    v-text-field(v-model='contactsOrgs[index].juristic_person_name' :title='contactsOrgs[index].juristic_person_name' solo :readonly='isCompanyReadonly[index]' :rules="formRule.contentLenRule50")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!showCompanyEditBtn[index] && !loading_OrgDetail[index]' @click='confirmEditContactOrg(index, contactsOrgs[index])') icon-check
                                                            v-icon(size='18' color="red" v-if='!showCompanyEditBtn[index] && !loading_OrgDetail[index]' @click='cancelEditDetail(2, index)') mdi-window-close  
                                                            v-icon.mr-1(size='18' v-if='!isEdit_2[index]' @click='onEditDetail(2, index)') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_OrgDetail[index]' indeterminate color='primary')
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label(:class='org == "" ?"py-2" : "" ')
                                                    label {{$t('CONTACT_DETAIL.POSITION')}}                                                    
                                                    v-text-field(v-model='contactsOrgs[index].position' solo :readonly='isCompanyReadonly[index]' :rules="formRule.contentLenRule20")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!showCompanyEditBtn[index] && !loading_OrgDetail[index]' @click='confirmEditContactOrg(index, contactsOrgs[index])') icon-check
                                                            v-icon(size='18' color="red" v-if='!showCompanyEditBtn[index] && !loading_OrgDetail[index]' @click='cancelEditDetail(2, index)') mdi-window-close  
                                                            v-icon.mr-1(size='18' v-if='!isEdit_2[index]' @click='onEditDetail(2, index)') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_OrgDetail[index]' indeterminate color='primary')
                                            v-col.pr-md-4(cols='12' md='6').my-1
                                                p.d-flex.align-center.input-has-label(:class='org == "" ?"py-2" : "" ')
                                                    label {{$t('CONTACT_DETAIL.SHAREHOLDING')}}
                                                    v-text-field(v-model='contactsOrgs[index].shareholding ' solo :readonly='isCompanyReadonly[index]' :rules="formRule.stockRules")
                                                        template(v-slot:append)
                                                            v-icon.mr-1(size='18' color="green" v-if='!showCompanyEditBtn[index] && !loading_OrgDetail[index]' @click='confirmEditContactOrg(index, contactsOrgs[index])') icon-check
                                                            v-icon(size='18' color="red" v-if='!showCompanyEditBtn[index] && !loading_OrgDetail[index]' @click='cancelEditDetail(2, index)') mdi-window-close  
                                                            v-icon.mr-1(size='18' v-if='!isEdit_2[index]' @click='onEditDetail(2, index)') icon-edit
                                                            v-progress-circular(size='18' v-if='loading_OrgDetail[index]' indeterminate color='primary')
                                // - note -     
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isNoteTab")
                                        v-btn(@click='onAddNote()' width='120' height='32' color='green' plain border-0)
                                            v-icon.mr-1(size='14') icon-add
                                            span {{$t('CONTACT_DETAIL.ADD_NOTE')}}
                                v-tab-item.pa-3(key='note' value='tab-2' :transition='false' :reverse-transition='false')
                                    .editor
                                    v-row.sheet-box.my-5(v-if='!hasType(timeLines, "NOTE")')
                                        v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                h5.ma-0.t-black {{$t('CONTACT_DETAIL.NO_NOTE')}}
                                    v-timeline.mt-5.pt-0(dense align-top)
                                        template(v-for='(item, index) in timeLines')
                                            v-timeline-item(v-if="item.type === 'NOTE'" :key='index' color='success' :icon='getTimeLineIcon(item.type)' small right fill-dot)
                                                v-card.px-3.py-1(flat outlined :class="item.pinned ? 'timeLine-note':'' ")
                                                    v-row.justify-space-between.align-center(no-gutters)
                                                        v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                            span.mr-3
                                                                | {{ item.timeConvert }} ・
                                                                | {{ item.owner }}
                                                        v-col.text-right(cols='1')
                                                            v-menu(bottom left)
                                                                template(v-slot:activator='{ on, attrs }')
                                                                    v-btn.ml-3(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                                        v-icon(size='16')  icon-dot-3
                                                                v-list.pa-0
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onPinNote(item)' text='text')
                                                                            v-icon.mr-1(v-if="item.pinned === true" size='10') fas fa-thumbtack
                                                                            span.ml-1 {{$t('GENERAL.PIN')}}
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onEditNote(item)' text='text')
                                                                            span.ml-1 {{$t('GENERAL.EDIT')}}
                                                                    v-list-item.px-0.text-center
                                                                        v-btn(@click='onDeleteNote(item.id)' text='text')
                                                                            span.ml-1 {{$t('GENERAL.DELETE')}}
                                                        v-col(cols='12')
                                                            .tab-note-box(v-if='item.isMore_detail')
                                                                vue-editor.can-select-text.v2e_no_border.v2e_post_max_height.ellipsis(ref='editor' v-model='item.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                                                                //- p.mb-1  {{ moreLess(item.content, "DETAIL") }}
                                                            div(v-else)
                                                                vue-editor.can-select-text.v2e_no_border( v-model='item.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                                                                //- p.mb-1  {{ item.content }}
                                                        v-col.d-flex.justify-end(cols='12' v-if='checkMore(item.content,300)')
                                                            v-btn.pa-0(v-if='item.isMore_detail' @click="isMore(index, 'DETAIL')" color='primary' text) MORE
                                                            v-btn.pa-0(v-if='!item.isMore_detail' @click="isMore(index, 'DETAIL')" color='primary' text) LESS
                                // - Email -
                                v-tab-item.pa-3(key='mail' value='tab-3' :transition='false' :reverse-transition='false')
                                    emailEditor(:objectDetail='contactsDetail' objectType='Contact' :contactsDetail='contactsDetail' :userEmail='userEmail' @emitSendEmailDialog='onEmitSendEmailDialog')
                                // - SMS -
                                v-tab-item.pa-3(key='sms' value='tab-4' :transition='false' :reverse-transition='false')
                                    smsEditor(:objectDetail='contactsDetail' objectType='Contact' :contactsDetail='contactsDetail' :userSms='userSms' @emitSendSmsDialog='onEmitSendSmsDialog')
                                // - News -
                                //- v-tab-item.pa-3(key='news' value='tab-5' :transition='false' :reverse-transition='false')
                                //-     newsDisplay()
                                // - Oppties -
                                v-tab-item.pa-3(key='oppties' value='tab-5' :transition='false' :reverse-transition='false')
                                    v-row.sheet-box.my-5(v-if='opportunities.length == 0')
                                        v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                h5.ma-0.t-black {{$t('CONTACT_DETAIL.NO_DEAL')}}
                                    .contact-detail-table.scroll-x.pb-0(v-else)
                                        .data-area
                                            v-data-table.height_100.table_in_tab( :headers='dealsHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='opportunities' :search='searchDeals' :items-per-page='itemPerPage' :page.sync='page' @page-count='pageCount = $event' multi-sort height='100%' hide-default-footer='hide-default-footer')
                                                template(v-slot:item.name='{ item }')
                                                    v-row(no-gutters)
                                                        v-col(cols='2').d-none.d-md-flex
                                                            v-system-bar(:color='item.stage_light_color.color')
                                                        v-col(cols='12' md='10').ellipsis.d-flex.align-center
                                                            a.pl-2.ellipsis(:title='item.name' :href='"/Deal/" + item.id') {{ item.name }}
                                                template(v-slot:item.closed_reason='{ item }')
                                                    v-chip.my-2.text-white(v-if='item.closed_reason == 1' color='green' small) Won
                                                    v-chip.my-2.text-black(v-else-if='item.closed_reason == null || item.closed_reason == " "'  small) Open
                                                    v-chip.my-2.text-white(v-else color='red' small) Lose
                                                template(v-slot:item.client='{ item }')
                                                    .ellipsis
                                                        a(:title='item.oppty_org.name' v-if='item.oppty_org' :href='"/Account/" + item.oppty_org.id') {{ item.oppty_org.name }}
                                                        a(:title='item.oppty_contact.__chineseName__' v-if='item.oppty_contact' :href='"/Contacts/" + item.oppty_contact.id') {{ item.oppty_contact.__chineseName__ }}
                                                template(v-slot:item.current_stage.sequence_number='{ item }')
                                                    .ellipsis
                                                        span(:title='item.current_stage.stage_name' ) {{ item.current_stage.stage_name }}
                                                template(v-slot:item.amount='{ item }')
                                                    .ellipsis
                                                        span(:title='getFormatAmount(item.amount)' v-if='item.amount') $ {{ getFormatAmount(item.amount) }}
                                                template(v-slot:item.expected_close_date='{ item }')
                                                    .ellipsis
                                                        span(:title='item.expected_close_date' v-if='item.expected_close_date') {{ item.expected_close_date }}
                                                template(v-slot:item.owner_name='{ item }')
                                                    .ellipsis
                                                        a.t-primary(:title='item.owner.last_name +" "+ item.owner.first_name' :href='onMail(item.owner.email)') {{ item.owner.last_name +' '+ item.owner.first_name }}
                                                template(v-slot:item.active='{ item }')
                                                    v-btn( icon='icon' @click='showToDealDialog(item)')
                                                        v-icon(size='18') icon-chatmoney
                                                    v-btn(:disabled='item.closed_reason >= 1' icon='icon' @click='onDeleteDeal(item)')
                                                        v-icon(size='18') icon-remove
                                            v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10") 
                                // - cases -
                                v-tab-item.pa-3(key='cases' value='tab-6' :transition='false' :reverse-transition='false')
                                    casesTable(:cases='openCases')
                                // - documents -
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isdocumentTab")
                                    v-btn(@click='onDelDocument()' width='120' height='32' color='red' plain border-0 v-if="isDelDocument")
                                        v-icon.mr-1(size='14') icon-remove
                                        span {{$t('DOCUMENT.DEL')}}
                                    v-btn(@click='onAddDocument()' width='120' height='32' color='green' plain border-0)
                                        v-icon.mr-1(size='14') icon-add
                                        span {{$t('DOCUMENT.ADD')}}
                                v-tab-item.pa-3(key='documents' value='tab-7' :transition='false' :reverse-transition='false')
                                    documentsTable(class_name='Contact' :target_id='target_id' @emitDocumentsList='selectDocumentsList' :isDel='openDelDocumentDialog' :addDocDialog ='isAddDoucment' @emitDocDialog='onEmitDocDialog' )
                                // - activity -
                                v-row.justify-md-end.mr-3.mt-3(no-gutters='no-gutters' v-if="isActivityTab")
                                    .btn-switch.ml-3.mb-3
                                        v-btn(:ripple='false' @click='activity_mode = "list"' width='30%' height='24' :color=" activity_mode == 'list'? 'primary':'white'"  depressed tile :dark='activity_mode != "list"')
                                            v-icon(size='14') icon-list
                                        v-btn(:ripple='false' @click='activity_mode = "calender"' width='30%' height='24' :color=" activity_mode == 'calender' ? 'primary' : 'white' " depressed tile :dark='activity_mode != "calender"')
                                            v-icon.icon-calendar.mt-1(size='16')
                                    v-btn(@click='onAddActivity()' width='120' height='32' color='green' plain border-0)
                                        v-icon.mr-1.mb-1(size='16') icon-add
                                        span.mb-1 {{$t('ACTIVITY.ADD')}}
                                v-tab-item.pa-3(key='activity' value='tab-8' :transition='false' :reverse-transition='false')
                                    activitiesTable(:isAddActivity='isAddActivity' @emitAddActivity='onEmitAddActivity' :target_id='target_id' :mode='activity_mode' :target_name='full_name' ,from_page='contact')
                    v-col.pt-4.pt-md-0.pl-md-4.min-pad-scroll(cols='12' md='4')
                        v-card.contact-card(flat)
                            v-card-title.d-flex.justify-space-between.align-center.px-3.py-2
                                .d-flex.align-center.my-0
                                    .cube-icon.small.bg-pink.mr-2
                                        v-icon(size='21' color='white') mdi-clock
                                    span {{$t('CONTACT_DETAIL.TIMELINE')}}
                            v-card-text.py-0
                                v-row.sheet-box.my-5(v-if='timeLines.length == 0')
                                    v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                        v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                            h5.ma-0.t-black {{$t('CONTACT_DETAIL.NO_TIMELINE')}}
                                v-tabs.borderBtn-tabs.pt-3(v-if='timeLines.length > 0 && !loading' height='32' background-color='white' hide-slider show-arrows centered)
                                    v-tab(v-for='item in timeLineType' :key='item.key' :id='`Tlt-${item.key}`'  @click='onChangeTltSelected' :class='changeSelectedColor(item.key)')
                                        | {{ item.name }}
                                div.pa-3.pt-4.scrollTimeLine(:style='timeline_max_height')
                                    v-timeline.pt-0(dense align-top)
                                        v-timeline-item(v-for='(item, index) in showTimelines' :key='index' :color='item.type == "DUE_ACTIVITY" ? "#FFD306" : "success"' :icon='getTimeLineIcon(item.type)' small right fill-dot v-if='item.show == true && item.show_index<timeline_count')
                                            //- // - Time Line Item - Meeting and Call  -
                                            //- v-card.px-3.py-1(v-if="item.type === 'call' || item.type === 'meeting'" flat outlined)
                                            //-     v-row.justify-space-between.align-center(no-gutters)
                                            //-         v-col(cols='10')
                                            //-             v-card-subtitle.pa-0.t-black
                                            //-                 | {{ item.title }}
                                            //-         v-col.text-right(cols='1')
                                            //-             v-menu(bottom left)
                                            //-                 template(v-slot:activator='{ on, attrs }')
                                            //-                     v-btn(color='primary' dark v-bind='attrs' v-on='on' icon)
                                            //-                         v-icon(size='16')  icon-dot-3
                                            //-                 v-list.pa-0
                                            //-                     v-list-item.px-0.text-center
                                            //-                         v-btn(@click='' text='text')
                                            //-                             span.ml-1 釘選
                                            //-                     v-list-item.px-0.text-center
                                            //-                         v-btn(@click='onEditAactivity(item)' text='text')
                                            //-                             span.ml-1 編輯
                                            //-                     v-list-item.px-0.text-center
                                            //-                         v-btn(@click='onDelete()' text='text')
                                            //-                             span.ml-1 刪除
                                            //-         v-col.f-smaller.t-lightgary.t-lightgary.detail.justify-start(cols='12')
                                            //-             v-row(no-gutters)
                                            //-                 v-col.mb-2(cols='12')
                                            //-                     span.mr-3
                                            //-                         | {{ item.date }} ・
                                            //-                         | {{ item.owner }}
                                            //-                 v-col.mb-1(cols='12')
                                            //-                     .d-inline-flex.align-center.mr-3
                                            //-                         v-icon(size='17') icon-group
                                            //-                         span  {{ item.client }}
                                            //-                     .d-inline-flex.align-center
                                            //-                         v-icon(size='15') icon-company
                                            //-                         span  {{ item.clientConpany }}
                                            // - Time Line Item - DUE ACTIVITY -
                                            v-card.px-3.py-1(v-if="item.type === 'DUE_ACTIVITY'" flat outlined :class='"timeLine-note"')
                                                v-row.justify-space-between.align-center(no-gutters)
                                                    v-col(cols='12')
                                                        p(v-if="item.is_due < 0").mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE")}}{{(item.is_due)*-1}}{{$t("DEAL_DETAIL.SOON_DUE_1")}}
                                                        p(v-else-if="item.is_due == 0").mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE")}}{{$t("DEAL_DETAIL.SOON_DUE_4")}}
                                                        p(v-else).mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE_2")}}{{item.is_due}}{{$t("DEAL_DETAIL.SOON_DUE_3")}}
                                            // Time Line Item - NOTE
                                            v-card.px-3.py-1(v-if="item.type === 'NOTE'" flat outlined :class="item.pinned ? 'timeLine-note':'' ")
                                                v-row.justify-space-between.align-center(no-gutters)
                                                    v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                        span.mr-3
                                                            | {{ item.timeConvert }} ・
                                                            | {{ item.owner }}
                                                    v-col.text-right(cols='2')
                                                        v-menu(bottom left)
                                                            template(v-slot:activator='{ on, attrs }')
                                                                v-btn(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                                    v-icon(size='16')  icon-dot-3
                                                            v-list.pa-0
                                                                v-list-item.px-0.text-center
                                                                    v-btn(@click='onPinNote(item)' text='text')
                                                                        v-icon.mr-1(v-if="item.pinned === true" size='10') fas fa-thumbtack
                                                                        span.ml-1 {{$t('GENERAL.PIN')}}
                                                                v-list-item.px-0.text-center
                                                                    v-btn(@click='onEditNote(item)' text='text')
                                                                        span {{$t('GENERAL.EDIT')}}
                                                                v-list-item.px-0.text-center
                                                                    v-btn(@click='onDeleteNote(item.id)' text='text')
                                                                        span {{$t('GENERAL.DELETE')}}
                                                    v-col(cols='12')
                                                        .timeLine-note-box(v-if='item.isMore_all')
                                                            vue-editor.can-select-text.v2e_no_border.v2e_post_max_height.ellipsis(ref='editor' v-model='item.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                                                            //- p.mb-1  {{ moreLess(item.content, "ALL") }}
                                                        div(v-else)
                                                            vue-editor.can-select-text.v2e_no_border( v-model='item.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                                                            //- p.mb-1  {{ item.content }}
                                                    v-col.d-flex.justify-end(cols='12' v-if='checkMore(item.content,100)')
                                                        v-btn.pa-0(v-if='item.isMore_all' @click='isMore(index, "ALL")' color='primary' text) MORE
                                                        v-btn.pa-0(v-if='!item.isMore_all' @click="isMore(index, 'ALL')" color='primary' text) LESS
                                            // - Time Line Item - EMAIL  -
                                            v-card.px-3.py-1(v-if="item.type === 'EMAIL'" flat outlined)
                                                v-row.justify-space-between.align-center(no-gutters)
                                                    v-col.mb-1.f-smaller.t-lightgary.d-flex.align-center(cols='11')
                                                        span.mr-3
                                                            | {{ item.timeConvert }}
                                                        div.mr-2
                                                            v-icon.mr-1(size='16') mdi-eye
                                                            span.ma-0 
                                                                | {{ item.opentime }}
                                                        div
                                                            v-icon.mr-1(size='16') mdi-link-variant 
                                                            span.ma-0 
                                                                | {{ item.linkclicktime }}
                                                    v-col.mb-1.f-smaller.t-lightgary.text-right(cols='1')
                                                        div
                                                            span
                                                                | {{ item.direction==true ?  $t('GENERAL.OUTGOING'):$t('GENERAL.INCOMING')}}
                                                    v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                        span.email-sapn
                                                            | {{$t('GENERAL.SENDER')}} :
                                                        a.mb-0( :href='onMail(item.sender_data.email)' :title='item.sender_data.sender_name' target='_blank') {{ item.sender_data.sender_name }} 
                                                    v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                        span.email-sapn
                                                            | {{$t('GENERAL.TO')}} :
                                                        div.auto-div
                                                            div(v-for='item,index in item.to_list' :key='item.email').mr-2.d-none.d-lg-inline-flex.auto-line
                                                                a.mb-0.ellipsis(v-if='item.name != ""' :href='onMail(item.email)' :title='item.name' target='_blank') {{ item.name }} ・
                                                                a.mb-0.ellipsis(v-else :href='onMail(item.email)' :title='item.email' target='_blank') {{ item.email }} ・
                                                    v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                        span.email-sapn
                                                            | {{$t('GENERAL.CC')}} :
                                                        div.auto-div
                                                            div(v-for='item,index in item.cc_list' :key='item.email').mr-2.d-none.d-lg-inline-flex.auto-line
                                                                a.mb-0.ellipsis(v-if='item.name != ""' :href='onMail(item.email)' :title='item.name' target='_blank') {{ item.name }} ・
                                                                a.mb-0.ellipsis(v-else :href='onMail(item.email)' :title='item.email' target='_blank') {{ item.email }} ・
                                                    //- v-col.text-right(cols='2')
                                                        v-menu(bottom left)
                                                            template(v-slot:activator='{ on, attrs }')
                                                                v-btn(color='primary' dark v-bind='attrs' v-on='on' icon)
                                                                    v-icon(size='16')  icon-dot-3
                                                            v-list.pa-0
                                                                v-list-item.px-0.text-center
                                                                    v-btn(text='text')
                                                                        span 回信
                                                    v-col.d-flex.align-center(cols='6')
                                                        v-card-subtitle.pa-0.t-black
                                                            | {{ item.email_subject }}                                                                            
                                                    v-col.d-none(cols='12 mt-2')
                                                        p.mb-1(v-html='item.email_body')
                                                v-row.justify-end.align-center.mt-3(no-gutters)
                                                    v-col.text-right(cols='12')
                                                        v-btn.mr-2(@click='showEmailDetail($event)' color='primary' text) {{$t('GENERAL.SHOW_CONTENT')}}
                                                        v-btn.mr-2(v-if='item.attachments.length > 0' icon @click='onEmailAttachment(item.attachments)')
                                                            v-icon(size='20') icon-attach
                                                        v-btn.mr-2(v-if='item.opportunities.length != 0' icon @click='onEmailOppties(item.opportunities)')
                                                            v-icon(size='20') icon-chatmoney-border
                                                        v-btn(icon @click='onEmailContact(item.contacts)')
                                                            v-icon(size='20') icon-contact-border
                                            // - Time Line Item - SMS  -
                                            v-card.px-3.py-1(v-if="item.type === 'SMS'" flat outlined)
                                                v-row.justify-space-between.align-center(no-gutters)
                                                    v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                        span
                                                            | {{ item.timeConvert }}
                                                    v-col(cols='12')
                                                        v-textarea.no-border.textarea-style.mb-2(v-model='item.sms_body' rows=2 readonly=true style='font-size: 14.4px;')
                                                    v-col.d-flex.justify-end(cols='12')
                                                        //- v-btn.pa-0(v-if='item.sms_body.length > 100 && item.isMore_all' @click='isMore(index, "ALL")' color='primary' text) MORE
                                                        //- v-btn.pa-0(v-if='item.sms_body.length > 100 && !item.isMore_all' @click="isMore(index, 'ALL')" color='primary' text) LESS
                                                        v-btn(icon @click='onEmailContact(item.contacts)')
                                                            v-icon(size='20') icon-contact-border
                                                        v-btn.mr-2(v-if='item.opportunities.length != 0' icon @click='onEmailOppties(item.opportunities)')
                                                            v-icon(size='20') icon-chatmoney-border
                                            // Time Line Item - FIELDHISTORY
                                            v-card.px-3.py-1(v-if="item.type === 'FIELDHISTORY' || item.type === 'ACTIVITY_FIELDHISTORY'" flat outlined)
                                                v-row.justify-space-between.align-center(no-gutters)
                                                    v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                                        span.mr-3
                                                            | {{ item.timeConvert }} ・
                                                            | {{ item.changed_by }}
                                                    v-col(cols='12')
                                                        p.mb-1.t-black  {{ item.content }}
                                            // Time Line Item - UPCHAT
                                            v-card.px-3.py-1(v-if="item.type === 'UPCHAT'" flat outlined)
                                                v-row.justify-space-between.align-center(no-gutters)
                                                    v-col.mb-1.f-smaller.t-lightgary.d-flex.align-center(cols='10')
                                                        span.mr-3
                                                            | {{ item.timeConvert }} ・
                                                            | {{ item.assignee.name }}
                                                    v-col.d-flex.align-center(cols='12')
                                                        v-card-subtitle.pa-0.t-black
                                                            | {{$t('UPCHAT.CHANNEL')}} :
                                                            | {{ get_upchat_channel(item.channel) }}
                                                        v-icon(size='20' :color='get_upchat_channel_iocn(item.channel).color') {{get_upchat_channel_iocn(item.channel).icon}}
                                                    v-col.d-none(cols='12 mt-2')
                                                        p.mb-1(v-html='item.email_body')
                                                v-row.justify-end.align-center.mt-3(no-gutters)
                                                    v-col.text-right(cols='12')
                                                        v-btn.mr-2(@click='showUpchatDialog(item.messages)' color='primary' text) {{$t('UPCHAT.SHOW')}}
                                    v-divider.pa-0.ma-0(id='timeline_btm' style='border-color:#00000000;')
                                            //- v-card-title 
                                //- // - Time Line - EMAIL -
                                //- v-tab-item.pa-0.pt-4(key='EMAIL' value='tab-2' :transition='false' :reverse-transition='false')
                                //-     v-row.sheet-box.my-5(v-if='!hasType(timeLines, "EMAIL")')
                                //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                //-                 h5.ma-0.t-black {{$t('GENERAL.NO_EMAIL')}}
                                //-     v-timeline.pt-0(v-else dense align-top)
                                //-         template(v-for='(item, index) in timeLines')
                                //-             v-timeline-item(v-if="item.type === 'EMAIL'" :key='index' color='success' :icon='getTimeLineIcon(item.type)' small right fill-dot)
                                //-                 v-card.px-3.py-1(v-if="item.type === 'EMAIL'" flat outlined)
                                //-                     v-row.justify-space-between.align-center(no-gutters)
                                //-                         v-col.mb-1.f-smaller.t-lightgary.d-flex.align-center(cols='11')
                                //-                             span.mr-3
                                //-                                 | {{ item.timeConvert }}
                                //-                             div.mr-2
                                //-                                 v-icon.mr-1(size='16') mdi-eye
                                //-                                 span.ma-0 
                                //-                                     | {{ item.opentime }}
                                //-                             div
                                //-                                 v-icon.mr-1(size='16') mdi-link-variant 
                                //-                                 span.ma-0 
                                //-                                     | {{ item.linkclicktime }}
                                //-                         v-col.mb-1.f-smaller.t-lightgary.text-right(cols='1')
                                //-                             div
                                //-                                 span
                                //-                                     | {{ item.direction==true ?  $t('GENERAL.OUTGOING'):$t('GENERAL.INCOMING')}}
                                //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                //-                             span.email-sapn
                                //-                                 | {{$t('GENERAL.SENDER')}} :
                                //-                             a.mb-0( :href='onMail(item.sender_data.email)' :title='item.sender_data.sender_name' target='_blank') {{ item.sender_data.sender_name }} 
                                //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                //-                             span.email-sapn
                                //-                                 | {{$t('GENERAL.TO')}} :
                                //-                             div.auto-div
                                //-                                 div(v-for='item,index in item.to_list' :key='item.email').mr-2.d-none.d-lg-inline-flex.auto-line
                                //-                                     a.mb-0.ellipsis(v-if='item.name != ""' :href='onMail(item.email)' :title='item.name' target='_blank') {{ item.name }} ・
                                //-                                     a.mb-0.ellipsis(v-else :href='onMail(item.email)' :title='item.email' target='_blank') {{ item.email }} ・
                                //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                //-                             span.email-sapn
                                //-                                 | {{$t('GENERAL.CC')}} :
                                //-                             div.auto-div
                                //-                                 div(v-for='item,index in item.cc_list' :key='item.email').mr-2.d-none.d-lg-inline-flex.auto-line
                                //-                                     a.mb-0.ellipsis(v-if='item.name != ""' :href='onMail(item.email)' :title='item.name' target='_blank') {{ item.name }} ・
                                //-                                     a.mb-0.ellipsis(v-else :href='onMail(item.email)' :title='item.email' target='_blank') {{ item.email }} ・
                                //-                         //- v-col.text-right(cols='2')
                                //-                             v-menu(bottom left)
                                //-                                 template(v-slot:activator='{ on, attrs }')
                                //-                                     v-btn(color='primary' dark v-bind='attrs' v-on='on' icon)
                                //-                                         v-icon(size='16')  icon-dot-3
                                //-                                 v-list.pa-0
                                //-                                     v-list-item.px-0.text-center
                                //-                                         v-btn(@click='' text='text')
                                //-                                             span 釘選
                                //-                                     v-list-item.px-0.text-center
                                //-                                         v-btn(text='text')
                                //-                                             span 回信
                                //-                                     v-list-item.px-0.text-center
                                //-                                         v-btn(@click='onDetele(item)' text='text')
                                //-                                             span 刪除
                                //-                         v-col.d-flex.align-center(cols='12')
                                //-                             v-card-subtitle.pa-0.t-black
                                //-                                 | {{ item.email_subject }}
                                //-                         v-col.d-none(cols='12 mt-2')
                                //-                             p.mb-1(v-html='item.email_body')
                                //-                     v-row.justify-end.align-center.mt-3(no-gutters)
                                //-                         v-col.text-right(cols='12')
                                //-                             v-btn.mr-2(@click='showEmailDetail($event)' color='primary' text) {{$t('GENERAL.SHOW_CONTENT')}}
                                //-                             v-btn.mr-2(v-if='item.attachments.length > 0' icon @click='onEmailAttachment(item.attachments)')
                                //-                                 v-icon(size='20') icon-attach
                                //-                             v-btn.mr-2(v-if='item.opportunities.length != 0' icon @click='onEmailOppties(item.opportunities)')
                                //-                                 v-icon(size='20') icon-chatmoney-border
                                //-                             v-btn(icon @click='onEmailContact(item.contacts)')
                                //-                                 v-icon(size='20') icon-contact-border
                                //- // - Time Line - SMS -
                                //- v-tab-item.pa-0.pt-4(key='SMS' value='tab-3' :transition='false' :reverse-transition='false')
                                //-     v-row.sheet-box.my-5(v-if='!hasType(timeLines, "SMS")')
                                //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                //-                 h5.ma-0.t-black {{$t('GENERAL.NO_SMS')}}
                                //-     v-timeline.pt-0(v-else dense align-top)
                                //-         template(v-for='(item, index) in timeLines')
                                //-             v-timeline-item(v-if="item.type === 'SMS'" :key='index' color='success' :icon='getTimeLineIcon(item.type)' small right fill-dot)
                                //-                 v-card.px-3.py-1(v-if="item.type === 'SMS'" flat outlined)
                                //-                     v-row.justify-space-between.align-center(no-gutters)
                                //-                         v-col.mb-1.f-smaller.t-lightgary.d-flex.align-center(cols='10')
                                //-                             span.mr-3
                                //-                                 | {{ item.timeConvert }}                                                      
                                //-                         //- v-col.text-right(cols='2')
                                //-                             v-menu(bottom left)
                                //-                                 template(v-slot:activator='{ on, attrs }')
                                //-                                     v-btn(color='primary' dark v-bind='attrs' v-on='on' icon)
                                //-                                         v-icon(size='16')  icon-dot-3
                                //-                                 v-list.pa-0
                                //-                                     v-list-item.px-0.text-center
                                //-                                         v-btn(@click='' text='text')
                                //-                                             span 釘選
                                //-                                     v-list-item.px-0.text-center
                                //-                                         v-btn(text='text')
                                //-                                             span 回信
                                //-                                     v-list-item.px-0.text-center
                                //-                                         v-btn(@click='onDetele(item)' text='text')
                                //-                                             span 刪除
                                //-                         v-col(cols='12 mt-2')
                                //-                             v-textarea.no-border.textarea-style.mb-2(v-model='item.sms_body' rows=2 readonly=true style='font-size: 14.4px;')
                                //-                             //- v-textarea.no-border.no-pointer-event.hide-scrollbar(v-if='item.isMore_note' v-model='item.sms_body' rows=3 no-resize=true style='font-size: 14.4px;')
                                //-                             //- v-textarea.no-border.no-pointer-event(v-else v-model='item.sms_body' auto-grow no-resize=true style='font-size: 14.4px;')
                                //-                     v-row.justify-end.align-center.mt-3(no-gutters)
                                //-                         v-col.text-right(cols='12')
                                //-                             //- v-btn.pa-0(v-if='item.sms_body.length > 100 && item.isMore_note' @click='isMore(index, "NOTE")' color='primary' text) MORE
                                //-                             //- v-btn.pa-0(v-if='item.sms_body.length > 100 && !item.isMore_note' @click="isMore(index, 'NOTE')" color='primary' text) LESS
                                //-                             v-btn(icon @click='onEmailContact(item.contacts)')
                                //-                                 v-icon(size='20') icon-contact-border
                                //-                             v-btn.mr-2(v-if='item.opportunities.length != 0' icon @click='onEmailOppties(item.opportunities)')
                                //-                                 v-icon(size='20') icon-chatmoney-border
                                //- // - Time Line - UPCHAT -
                                //- v-tab-item.pa-0.pt-4(key='UPCHAT' value='tab-4' :transition='false' :reverse-transition='false')
                                //-     v-row.sheet-box.my-5(v-if='!this.chat_data')
                                //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                //-                 h5.ma-0.t-black {{$t('UPCHAT.NO_MESSAGE')}}
                                //-     v-timeline.pt-0(v-else dense align-top)
                                //-         template(v-for='(item, index) in this.chat_data')
                                //-             v-timeline-item(:key='index' color='success' icon='mdi-message-text-outline' small right fill-dot)
                                //-                 v-card.px-3.py-1( flat outlined)
                                //-                     v-row.justify-space-between.align-center(no-gutters)
                                //-                         v-col.mb-1.f-smaller.t-lightgary.d-flex.align-center(cols='10')
                                //-                             span.mr-3
                                //-                                 | {{ item.timeConvert }} ・
                                //-                                 | {{ item.assignee.name }}
                                //-                         v-col.d-flex.align-center(cols='12')
                                //-                             v-card-subtitle.pa-0.t-black
                                //-                                 | {{$t('UPCHAT.CHANNEL')}} :
                                //-                                 | {{ get_upchat_channel(item.channel) }}
                                //-                             v-icon(size='20' :color='get_upchat_channel_iocn(item.channel).color') {{get_upchat_channel_iocn(item.channel).icon}}
                                //-                         v-col.d-none(cols='12 mt-2')
                                //-                             p.mb-1(v-html='item.email_body')
                                //-                     v-row.justify-end.align-center.mt-3(no-gutters)
                                //-                         v-col.text-right(cols='12')
                                //-                             v-btn.mr-2(@click='showUpchatDialog(item.messages)' color='primary' text) {{$t('UPCHAT.SHOW')}}

                                //- // - Time Line - NOTE -
                                //- v-tab-item.pa-0.pt-4(key='NOTE' value='tab-5' :transition='false' :reverse-transition='false')
                                //-     v-row.sheet-box.my-5(v-if='!hasType(timeLines, "NOTE")')
                                //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                //-                 h5.ma-0.t-black {{$t('CONTACT_DETAIL.NO_NOTE')}}
                                //-     v-timeline.pt-0(v-else dense align-top)
                                //-         template(v-for='(item, index) in timeLines')
                                //-             v-timeline-item(v-if="item.type === 'NOTE'" :key='index' color='success' :icon='getTimeLineIcon(item.type)' small right fill-dot)
                                //-                 v-card.px-3.py-1(v-if="item.type === 'NOTE'" flat outlined :class="item.pinned ? 'timeLine-note':'' ")
                                //-                     v-row.justify-space-between.align-center(no-gutters)
                                //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                //-                             span.mr-3
                                //-                                 | {{ item.timeConvert }} ・
                                //-                                 | {{ item.owner }}
                                //-                         v-col.text-right(cols='2')
                                //-                             v-menu(bottom left)
                                //-                                 template(v-slot:activator='{ on, attrs }')
                                //-                                     v-btn(color='primary' dark v-bind='attrs' v-on='on' icon)
                                //-                                         v-icon(size='16')  icon-dot-3
                                //-                                 v-list.pa-0
                                //-                                     v-list-item.px-0.text-center
                                //-                                         v-btn(@click='onPinNote(item)' text='text')
                                //-                                             v-icon.mr-1(v-if="item.pinned === true" size='10') fas fa-thumbtack
                                //-                                             span.ml-1 {{$t('GENERAL.PIN')}}
                                //-                                     v-list-item.px-0.text-center
                                //-                                         v-btn(@click='onEditNote(item)' text='text')
                                //-                                             span.ml-1 {{$t('GENERAL.EDIT')}}
                                //-                                     v-list-item.px-0.text-center
                                //-                                         v-btn(@click='onDeleteNote(item.id)' text='text')
                                //-                                             span.ml-1 {{$t('GENERAL.DELETE')}}
                                //-                         v-col(cols='12')
                                //-                             .timeLine-note-box(v-if='item.isMore_note')
                                //-                                 p.mb-1  {{ moreLess(item.content, "NOTE") }}
                                //-                             div(v-else)
                                //-                                 p.mb-1  {{ item.content }}
                                //-                         v-col.d-flex.justify-end(cols='12' v-if='item.content.length > 100')
                                //-                             v-btn.pa-0(v-if='item.isMore_note' @click='isMore(index, "NOTE")' color='primary' text) MORE
                                //-                             v-btn.pa-0(v-if='!item.isMore_note' @click="isMore(index, 'NOTE')" color='primary' text) LESS
                                //- // - Time Line - FIELDHISTORY -
                                //- v-tab-item.pa-0.pt-4(key='FIELDHISTORY' value='tab-6' :transition='false' :reverse-transition='false')
                                //-     v-row.sheet-box.my-5(v-if='!hasType(timeLines, "FIELDHISTORY")')
                                //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                //-                 h5.ma-0.t-black {{$t('GENERAL.NO_FIELDHISTORY')}}
                                //-     v-timeline.pt-0(v-else dense align-top)
                                //-         template(v-for='(item, index) in timeLines')
                                //-             v-timeline-item(v-if="item.type === 'FIELDHISTORY'" :key='index' color='success' :icon='getTimeLineIcon(item.type)' small right fill-dot)
                                //-                     v-card.px-3.py-1(v-if="item.type === 'FIELDHISTORY'" flat outlined)
                                //-                         v-row.justify-space-between.align-center(no-gutters)
                                //-                             v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                //-                                 span.mr-3
                                //-                                     | {{ item.timeConvert }} ・
                                //-                                     | {{ item.changed_by }}
                                //-                             v-col(cols='12')
                                //-                                 p.mb-1.t-black  {{ item.content }}
                                //- // - Time Line activity
                                //- v-tab-item.pa-0.pt-4(key='ACTIVITY' value='tab-7' :transition='false' :reverse-transition='false')
                                //-     v-row.sheet-box.my-5(v-if='!hasType(activityTimeline, "ACTIVITY_FIELDHISTORY")')
                                //-         v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                                //-             v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                //-                 h5.ma-0.t-black {{$t('DEAL_DETAIL.NO_ACTIVITY_CHANGE')}}
                                //-     v-timeline.pt-0(v-else dense align-top)
                                //-         template(v-for='(item, index) in timeLines')
                                //-             v-timeline-item(v-if="item.type === 'DUE_ACTIVITY'" :key='item.id' color='#FFDC35' icon='mdi-bell-ring' small right fill-dot)
                                //-                 v-card.px-3.py-1(v-if="item.type === 'DUE_ACTIVITY'" flat outlined class="timeLine-note")
                                //-                     v-row.justify-space-between.align-center(no-gutters)
                                //-                         v-col(cols='12')
                                //-                             p(v-if="item.is_due < 0").mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE")}}{{(item.is_due)*-1}}{{$t("DEAL_DETAIL.SOON_DUE_1")}}
                                //-                             p(v-else-if="item.is_due == 0").mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE")}}{{$t("DEAL_DETAIL.SOON_DUE_4")}}
                                //-                             p(v-else).mb-1.t-black  {{ item.type_name }} {{$t("Bower_Title.Activity")}} {{ item.subject }} {{$t("DEAL_DETAIL.SOON_DUE_2")}}{{item.is_due}}{{$t("DEAL_DETAIL.SOON_DUE_3")}}
                                //-         template(v-for='(item, index) in activityTimeline')
                                //-             v-timeline-item(v-if="item.type === 'ACTIVITY_FIELDHISTORY'" :key='index' color='success' icon='icon-calendar' small right fill-dot)
                                //-                 v-card.px-3.py-1(v-if="item.type === 'ACTIVITY_FIELDHISTORY' " flat outlined)
                                //-                     v-row.justify-space-between.align-center(no-gutters)
                                //-                         v-col.f-smaller.t-lightgary.t-lightgary.d-flex.align-center(cols='10')
                                //-                             span.mr-3
                                //-                                 | {{ item.timeConvert }} ・
                                //-                                 | {{ item.changed_by }}
                                //-                         v-col(cols='12')
                                //-                             p.mb-1.t-black  {{ item.content }}
        v-dialog(v-model='changeOwnDialog' max-width='500' v-if='changeOwnDialog')
            change-own(@emitChangeOwnDialog='onEmitChangeOwnDialog' :owner-data='changeOwnData' :other-data='otherData' change='contacts')
        v-dialog(v-model='addNoteDialog' max-width='500' v-if='addNoteDialog')
            add-note(@emitAddNoteDialog='onEmitAddNoteDialog' @emitAddNoteData='onEmitAddNoteData' :note-data='noteData')
        v-dialog(v-model='editNoteDialog' max-width='500' v-if='editNoteDialog')
            edit-note(@emitEditNoteDialog='onEmitEditNoteDialog' :note-data='noteData')
        v-dialog(v-model='emailContactsDialog' max-width='300' v-if='emailContactsDialog' scrollable)
            email-contacts-dialog(@emitEmailContactsDialog='onEmitEmailContactsDialog' :email-contact-data='emailContactData')
        v-dialog(v-model='emailAttachmentsDialog' max-width='300' v-if='emailAttachmentsDialog' scrollable)
            email-attachments-dialog(@emitEmailAttachmentsDialog='onEmitEmailAttachmentsDialog' :email-attachment-data='emailAttachmentData')
        v-dialog(v-model='activityDialog' width='470' data-app='true')
            activity-dialog(@emitActivityDialog='onEmitActivityDialog')
        v-dialog(v-model='addDealDialog' max-width='600' v-if='addDealDialog')
            add-deal(@emitAddDealDialog='onEmitAddDealDialog' @emitNewDealData='onEmitNewDealData' :add-deal='addDealData')
        v-dialog(v-model='toDealDialog' max-width='1200' v-if='toDealDialog')
            to-deal(@emitToDealDialog='onEmitToDealDialog' :oppty-data='opptyData')
        v-dialog(v-model='addCaseDialog' max-width='600' v-if='addCaseDialog')
            add-case(@emitAddCaseDialog='onEmitAddCaseDialog' @emitNewCaseData='onEmitNewCaseData' :add-case='addCaseData')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='addContactOrg' max-width='500' v-if='addContactOrg')
            add-contact-org(@emitAddContactOrgDialog='onEmitAddContactOrgDialog' @emitAddContactOrgData='onEmitAddContactOrgData' :contact-org-data='newContactOrgData')        
        v-dialog(v-model='upchatDialog' width='600' content-class='statusDialog')
            upchat-dialog(@emitUpchatDialog='onEmitUpchatDialog' :chatMessage='chatMessage')
</template>

<script>
    import refreshBtn from "@/components/Common/refreshBtn";
    // dialog    
    import changeOwn from '@/components/Dialog/changeOwn';
    import addContactOrg from '@/components/Dialog/Contact/addContactOrg';
    import addNote from '@/components/Dialog/addNote';
    import editNote from '@/components/Dialog/editNote';
    import emailContactsDialog from '@/components/Dialog/emailContactsDialog';
    import emailOpptiesDialog from '@/components/Dialog/emailOpptiesDialog';
    import emailAttachmentsDialog from '@/components/Dialog/emailAttachmentsDialog';
    import activityDialog from '@/components/Dialog/activityDialog';
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import errorDialog from '@/components/Dialog/errorDialog';
    import addDeal from '@/components/Dialog/Deal/addDeal';
    import toDeal from '@/components/Dialog/Deal/toDeal';
    import addCase from '@/components/Dialog/Case/addCase';
    import upchatDialog from "@/components/Common/upchatDialog";
    // array
    import levelList from "@/array/the_levelList";
    // other
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import customField from "@/components/Common/customField";
    import emailEditor from '@/components/Common/emailEditor';
    import smsEditor from '@/components/Common/smsEditor';
    import newsDisplay from '@/components/Common/newsDisplay';
    import caseCard from "@/components/Common/caseCard";
    import opptyCard from "@/components/Common/opptyCard";
    import casesTable from "@/components/Common/casesTable";
    import documentsTable from "@/components/Common/documentsTable";
    import activitiesTable from "@/components/Common/activitiesTable";
    import followBtn from "@/components/Common/followBtn";
    import { VueEditor } from "vue2-editor";
    // service
    import ContactDataService from "../services/ContactDataService";
    import OrganizationDataService from "../services/OrganizationDataService";   
    import OpportunityDataService from "@/services/OpportunityDataService"; 
    import NoteDataService from "../services/NoteDataService";
    import CaseDataService from "@/services/CaseDataService";
    import CampaignDataService from "@/services/CampaignDataService";
    import O365Service from "@/services/O365Service";
    import UpChatDataService from "@/services/UpChatDataService";
    import ActivityDataService from '../services/ActivityDataService';
    import PersonalSettingService from '../services/PersonalSettingService';

    import i18n from '/common/plugins/vue-i18n.js' 

    export default {
        components: {
            // dialog
            changeOwn,
            addContactOrg,
            addNote,
            editNote,
            emailContactsDialog,
            emailOpptiesDialog,
            emailAttachmentsDialog,
            activityDialog,
            messageDialog,
            successDialog,
            errorDialog,
            addDeal,
            toDeal,
            addCase,
            upchatDialog,
            // other
            customField,
            emailEditor,
            smsEditor,
            newsDisplay,
            loadingOverlay,
            caseCard,
            opptyCard,
            casesTable,
            documentsTable,
            refreshBtn,
            activitiesTable,
            followBtn,
            VueEditor,
        },
        data() {
            return {
                // vue editor
                editorSettings_no_tool: {
                    modules: {
                        imageResize: false,
                        toolbar:false
                        },
                },
                valid: true,
                loading_contactDetail: false,
                loading: true,
                comboBoxIsLoading: false,
                tab: null,
                campaignList:[],
                opportunities: {},
                openOppties: [],
                organizationList: [],
                search: null,
                levelList: levelList,
                contactImage: null,
                contactsDetail: {},
                contactsOrgs: [],
                head_org: {},
                comboText: "",
                userEmail: '',
                userSms: '',
                menu: false,
                date: null,
                // TimeLine                
                showNoteMore: false,
                timeLines:[],
                showTimelines:[],
                OriginalTimeLines: [],
                isNoteTab: false,
                noteData: {},
                fieldhistoryData: {},
                noteIdOnDelete: null,
                onClickNote: {},
                tabs: [
                    {
                        id: 1,
                        key: 'detail',
                        name: i18n.t('CONTACT_DETAIL.DETAIL')
                    },
                    {
                        id: 2,
                        key: 'note',
                        name: i18n.t('CONTACT_DETAIL.NOTE')
                    },
                    {
                        id: 3,
                        key: 'mail',
                        name: i18n.t('CONTACT_DETAIL.EMAIL')
                    },
                    {
                        id: 4,
                        key: 'sms',
                        name: i18n.t('CONTACT_DETAIL.SMS')
                    },
                    // {
                    //     id: 4,
                    //     key: 'news',
                    //     name: '新聞'
                    // }
                    {
                        id: 5,
                        key: 'oppties',
                        name: i18n.t('CONTACT_DETAIL.ALL_OPPORTUNITIES')
                    },
                    {
                        id: 6,
                        key: 'cases',
                        name: i18n.t('CONTACT_DETAIL.ALL_CASES')
                    },
                    {
                        id: 7,
                        key: 'documents',
                        name: i18n.t('DOCUMENT.TITLE')
                    },
                    {
                        id: 8,
                        key: 'activities',
                        name: i18n.t('Bower_Title.Activity')
                    },
                ],
                timeLineType: [
                    {
                        id: 1,
                        key: 'all',
                        name: i18n.t('GENERAL.ALL')
                    },
                    {
                        id: 2,
                        key: 'EMAIL',
                        name: i18n.t('CONTACT_DETAIL.EMAIL')
                    },
                    {
                        id: 3,
                        key: 'SMS',
                        name: i18n.t('CONTACT_DETAIL.SMS')
                    },
                    {
                        id: 4,
                        key: 'UPCHAT',
                        name: i18n.t('CONTACT_DETAIL.UPCHAT')
                    },
                    {
                        id: 5,
                        key: 'NOTE',
                        name: i18n.t('CONTACT_DETAIL.NOTE')
                    },
                    {
                        id: 6,
                        key: 'FIELDHISTORY',
                        name: i18n.t('GENERAL.FIELDHISTORY')
                    },
                    {
                        id: 7,
                        key: 'activity',
                        name: i18n.t('Bower_Title.Activity')
                    }
                ],
                timeline_tab: ['all'],
                timelinetabData: {},
                breadcrumbs: [{
                        text: i18n.t('BREADCRUMBS.Contacts'),
                        disabled: false,
                        href: '/Contacts',
                    },
                    {
                        text: "",
                        disabled: true,
                        href: '',
                    },
                ],
                showContactEditBtn: true,
                showCompanyEditBtn: [],
                showNewCompanyEditBtn: true,
                isContactReadonly: true,
                isCompanyReadonly: [],
                isNewCompanyReadonly: true,
                own: 'James Campbell',
                changeOwnData: {
                    usersetting: {
                        phtoto: ""
                    }
                },
                otherData: {},
                // oppties data table
                page: 1,
                pageCount: 0,
                searchDeals: '',
                itemPerPage: 12,
                dealsHeaders: [
                    {
                        text: i18n.t('CONTACT_DETAIL.DEAL'),
                        value: 'name',
                        width: '20%',
                        align: 'left'
                    },
                    {
                        text: i18n.t('TO_CASE.STATUS'),
                        value: 'closed_reason',
                        align: 'center',
                    },
                    {
                        text: i18n.t('CONTACT_DETAIL.STAGE'),
                        value: 'current_stage.sequence_number',
                        width: '15%',
                        align: 'left'
                    },
                    {
                        text: i18n.t('TO_DEAL.AMOUNT'),
                        value: 'amount',
                        width: '15%',
                        align: 'right',
                    },
                    {
                        text: i18n.t('TO_DEAL.EXPECTED_CLOSE_DATE'),
                        value: 'expected_close_date',
                        width: '18%',
                        align: 'center'
                    },
                    {
                        text: i18n.t('GENERAL.ACTIVE'),
                        value: 'active',
                        align: 'center',
                        sortable: false,
                    },
                ],
                // Dialog
                toCaseDialog:false,
                addContactOrg: false,
                newContactOrgData: {},
                addDealDialog: false,
                addCaseDialog: false,
                toDealDialog: false,
                changeOwnDialog: false,
                addNoteDialog: false,
                editNoteDialog: false,
                addContactDialog: false,
                emailContactsDialog: false,
                emailOpptiesDialog: false,
                emailAttachmentsDialog: false,
                contactData: '',
                activityDialog: false,
                messageDialog: false,
                message: '',
                successDialog: false,
                errorDialog: false,
                errorMessage: '',
                delMode: '',
                succMode: '',
                originContactData: {},
                originContactOrgData: [],
                isEdit_1: false,
                isEdit_2: [],
                isEdit_3: false,
                loading_OrgDetail: [],
                emailOpptiesData: null,
                emailContactData: null,
                emailAttachmentData: null,
                opptyData: null,
                customFieldData:{},
                addDealData: {},
                isNumVaild: true,
                vue_tel: {
                    isValid: true,
                },
                delDealData: {},
                formRule: {
                    nameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    emailRules: [
                        (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    // phoneRules: [
                    //     (v) => /^\d*$|(^$)|null/.test(v) || "請填寫數字",
                    //     (v) => /^.{0,20}$/.test(v) || "字數請限制在20字內",
                    // ],
                    urlRules: [
                        (v) => /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$|(^$)|null/.test(v) || i18n.t('RULE.RULE_URL'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
                    ],
                    fbRules: [
                        (v) => /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!]))?|(^$)|null/.test(v) || i18n.t('RULE.RULE_HTTP'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
                    ],
                    stockRules: [
                        (v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
                        (v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10')
                    ],
                    phoneRules: [],
                    contentLenRule70: [(v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')],
                    contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                    contentLenRule20: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
                    contentLenRule10: [(v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10')],
                    companyRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
                    ],
                },
                isPhoto: true,
                levelName: "",
                editOrg: {},
                orgIndex: 0,
                newOrgContact: {
                    organization: {
                        id: "",
                        nema: "",
                        company_number: "",
                    },
                    juristic_person_name: '',
                    position: '',
                    shareholding: '',
                },
                alertNote: [],
                pinning: true,
                getfolder: true,
                addCaseData: {},
                openCasesCount: 0,
                openCases: [],
                chat_data:null,
                chat_message:[],
                upchatDialog:false,
                chatMessage:null,
                target_id: this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id'),
                //document
                isdocumentTab:false,
                isDelDocument:false,
                openDelDocumentDialog:false,
                isAddDoucment:false,
                user_list:[],
                // activity
                activity_mode:"list",
                isActivityTab:false,
                isAddActivity:false,
                activityTimeline:[],
                originTimeLines:[],
                due_activity_list:[],
                full_name: "",
                isFollow:false,
                timeline_count:0,
                timeline_show_count:0,
                timeline_max_height:'',
            }
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize)
        },
        async created(){
            await (this.uuidCheck(this.target_id))
            await (this.loading = true);
            this.getLoginUserEmail();
            await ContactDataService.getContactsDetail(this.target_id)
            .then(response => {
                this.contactsDetail = response.data;
                this.isFollow = this.contactsDetail.follow;
                this.full_name = this.contactsDetail.first_name ? this.contactsDetail.last_name + ' ' + this.contactsDetail.first_name : this.contactsDetail.last_name;
                this.set_title();
                this.contactsOrgs = response.data.organizations;
                this.contactsOrgs.forEach(() => {
                    this.isEdit_2.push(false);
                    this.loading_OrgDetail.push(false);
                    this.isCompanyReadonly.push(true);
                    this.showCompanyEditBtn.push(true);
                });
                this.check = [this.contactsDetail.title,this.contactsDetail.department,this.contactsDetail.level];
                this.opportunities = this.contactsDetail.opportunity_set;
                this.openOppties = this.opportunities.filter(item => item.closed_reason < 1 || item.closed_reason == null || item.closed_reason == undefined || item.closed_reason == " ");
                this.changeOwnData = this.contactsDetail.owner;
                if (this.contactsOrgs.length > 0) {
                    this.head_org = this.contactsOrgs[0].organization;
                }
                if (this.contactsDetail.owner.usersetting.photo == null) {
                    this.isPhoto = false;
                }
                this.levelName = this.getLevelName();
                this.getBreadCrumb();
                if(response.data.custom_field != null){
                    
                    this.customFieldData =  response.data.custom_field;
                }
            })
            .catch(response => {
                if (response.response.status == 403)
                {
                    this.$router.replace('/PermissionError');
                }
                else if(response.response.status == 404)
                {
                    this.$router.replace('/PageNotFound');
                }
            })
            const caseSearch = {"contact":this.target_id}
            await CaseDataService.detailcard(caseSearch).then((response)=>{
                this.openCases = response.data;
            })
            await OrganizationDataService.getchangeowner('contacts')
            .then((respose)=>{
                this.user_list = respose.data;
                this.user_list.forEach(item => {
                    item.fullName = item.last_name + ' ' + item.first_name;
                });
            });
            await this.getContactTimeline(this.target_id);
            await CampaignDataService.selectlist().then((response)=>{
                this.campaignList = response.data;
            })     
            await this.getActivityTimeline();
            await PersonalSettingService.getCurrentUserDetail()
            .then(response => {
                if(response.data[0]['usersetting']['timeline_tab'] != null){
                    if(response.data[0]['usersetting']['timeline_tab']['Contact'] != undefined){
                        this.timeline_tab = response.data[0]['usersetting']['timeline_tab']['Contact'];
                    }
                    else{
                        this.timeline_tab = ['all'];
                    }
                }
                else{
                    this.timeline_tab = ['all'];
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
            });
            await this.showTimlinesCheck();
            await (this.loading = false);
            window.addEventListener('resize', this.onResize)
            this.onResize();

            const io = new IntersectionObserver((mutations) => {
                const isTimelineBtm = mutations[0].isIntersecting;
                if(isTimelineBtm && this.timeline_show_count > this.timeline_count){
                    this.timeline_count = this.timeline_count + 100;
                }
            });
            io.observe(document.getElementById('timeline_btm'));
        },
        updated() {
            let n = 0
            this.openCases.forEach(caseItem => {
                if (caseItem.closed_reason == null) {
                    n ++;
                }
            });
            this.openCasesCount = n;
        },
        methods: {
            checkMore(content,length){
                if(content.replace( /(<([^>]+)>)/ig, '').length > length || content.includes('base64')){
                    return true;
                }else{
                    return false;
                }
            },
            onEmitChangeFollow(val){
                this.contactsDetail.follow = val;
                this.isFollow  = val;
            },
            set_title(){
                const title = localStorage.getItem('title');
                let title_name = ''
                let tab_name = ''
                if(this.contactsDetail.first_name != '' && this.contactsDetail.first_name != null){
                    title_name = this.contactsDetail.last_name+' '+this.contactsDetail.first_name;
                    tab_name = this.contactsDetail.first_name+' '+this.contactsDetail.last_name;
                }else{
                    title_name = this.contactsDetail.last_name;
                    tab_name = this.contactsDetail.last_name;
                }
                document.title = title+' : '+title_name +' – UpDay';
                return tab_name;
            },
            validateForm(){
                let flag1 = this.$refs.formContact.validate();
                let flag2 = this.$refs.formOrg.validate();

                if(flag1 && flag2)
                    return true;
                else
                    return false;
            },
            changeSelectedColor(key){
                if(this.timeline_tab.includes(key)){
                    return 'v-tab tabBtn-selected';
                }
                else{
                    return 'v-tab tabBtn-unselect';
                }
            },
            onChangeTltSelected(){
                let choosedbtn = document.getElementById(event.target.id);
                let choosedbtnkey = choosedbtn.getAttribute('id').substring(4);
                if(choosedbtnkey == 'all'){
                    this.timeline_tab = ['all'];
                }
                else{
                    if(this.timeline_tab.includes('all')){
                        this.timeline_tab.pop();
                        this.timeline_tab.push(choosedbtnkey);
                    }
                    else if(this.timeline_tab.includes(choosedbtnkey)){
                        let index = this.timeline_tab.indexOf(choosedbtnkey);
                        this.timeline_tab.splice(index,1);
                        if(this.timeline_tab.length == 0)
                            this.timeline_tab = ['all'];
                    }
                    else{
                        this.timeline_tab.push(choosedbtnkey);
                        if(this.timeline_tab.length == (this.timeLineType.length-1)) 
                            this.timeline_tab = ['all'];
                    }
                }
                let user = JSON.parse(window.localStorage.getItem('user'));
                let userid = user['userid'];
                this.timelinetabData['user_id'] = userid;
                this.timelinetabData['Contact'] = this.timeline_tab;
                PersonalSettingService.updateUserTimelineTab(userid,this.timelinetabData)
                .catch(response => {
                    this.showErrorDialog(response);
                })
                this.showTimlinesCheck();               
            },
            showTimlinesCheck(){
                this.showTimelines = this.timeLines;
                for(let i = 0,ll = this.showTimelines.length ; i<ll ; i++){
                    // 預設show全部false
                    this.showTimelines[i]['show'] = false;
                    // 到期活動 & 釘選改為 true
                    if(this.showTimelines[i]['type'] == 'DUE_ACTIVITY')
                        this.showTimelines[i]['show'] = true;
                    if(this.showTimelines[i]['pinned'] !== undefined && this.showTimelines[i]['pinned'] == true)
                        this.showTimelines[i]['show'] = true;
                    // 當篩選為all 改為 全true
                    if(this.timeline_tab.includes('all'))
                        this.showTimelines[i]['show'] = true;
                    // 依篩選調整顯示項目
                    if(this.timeline_tab.includes('EMAIL')){
                        if(this.showTimelines[i]['type'] == 'EMAIL')
                            this.showTimelines[i]['show'] = true;
                    }
                    if(this.timeline_tab.includes('SMS')){
                        if(this.showTimelines[i]['type'] == 'SMS')
                            this.showTimelines[i]['show'] = true;
                    }
                    if(this.timeline_tab.includes('UPCHAT')){
                        if(this.showTimelines[i]['type'] == 'UPCHAT')
                            this.showTimelines[i]['show'] = true;
                    }
                    if(this.timeline_tab.includes('NOTE')){
                        if(this.showTimelines[i]['type'] == 'NOTE')
                            this.showTimelines[i]['show'] = true;
                    }
                    if(this.timeline_tab.includes('FIELDHISTORY')){
                        if(this.showTimelines[i]['type'] == 'FIELDHISTORY')
                            this.showTimelines[i]['show'] = true;
                    }
                    if(this.timeline_tab.includes('activity')){
                        if(this.showTimelines[i]['type'] == 'ACTIVITY_FIELDHISTORY')
                            this.showTimelines[i]['show'] = true;
                    }
                }
                let showList = this.showTimelines.filter(el => el.show == true);
                for (let i in showList){
                    showList[i]['show_index'] = parseInt(i);
                }
                this.timeline_show_count = showList.length;
                this.timeline_count = 100;
            },
            getBreadCrumb(){
                if (this.contactsDetail.first_name == null) {
                    this.breadcrumbs[1].text = this.last_name;
                } else {
                    this.breadcrumbs[1].text = this.contactsDetail.last_name + " " + this.contactsDetail.first_name;
                }
            },
            changephoto(){
                this.$refs.uploader.$refs.input.click()
            },
            uploadimage(event){
                if (event != null) {
                    // file type
                    let n = event.type.indexOf("image");
                    if (n == -1) {
                        this.showErrorDialog(i18n.t('CONTACT_DETAIL.ERROR'));
                    } else {
                    // photo size
                        if (event.size > 5242880) {
                            this.showErrorDialog(i18n.t('CONTACT_DETAIL.ERROR_2'));
                        } else {
                            ContactDataService.image(this.contactsDetail.id, this.contactImage)
                            .then(response => {
                                this.contactsDetail.image = response.data.image;              
                            });
                        }
                    }
                }
            },
            onResize: function () {
                let windowH = window.innerHeight - 420; //table高度
                let objH = 48;
                this.itemPerPage = parseInt(windowH/objH);
                setTimeout(()=>{
                    let timelineHeight = document.getElementsByClassName('v-window__container')[0].offsetHeight;
                    this.timeline_max_height = `max-height:${timelineHeight-159}px;`
                },100);
            },
            onCall: function (cell) {
                return 'tel:'+cell;
            },
            onMail: function (mail) {
                return 'mailto:'+mail;
            },
            onGoogleMap: function (address) {
                return "https://www.google.com.tw/maps/place/" + address;
            },
            getIndex(array, obj) {
                let index = array.findIndex(item => {
                    return item.id === obj.id
                });
                return index;
            },
            // About Deal
            onEditDetail: function(section, index) {    
                switch (section) {
                    case 1:
                        this.originContactData = JSON.parse(JSON.stringify(this.contactsDetail));
                        this.isEdit_1 = true;
                        this.isContactReadonly = false;
                        this.showContactEditBtn = false;
                        break;
                    case 2:
                        {
                            this.orgIndex = index;
                            var editOrgIndex = this.isEdit_2.findIndex(isEdit => {return isEdit;});
                            if (editOrgIndex != -1) {
                                this.contactsOrgs = this.originContactOrgData;
                                this.$set(this.isEdit_2, editOrgIndex, false);
                                this.$set(this.isCompanyReadonly, editOrgIndex, true);
                                this.$set(this.showCompanyEditBtn, editOrgIndex, true);
                            } else {
                                this.originContactOrgData = JSON.parse(JSON.stringify(this.contactsOrgs));
                            }
                            this.$set(this.isEdit_2, index, true);
                            this.$set(this.isCompanyReadonly, index, false);
                            this.$set(this.showCompanyEditBtn, index, false);
                            break;
                        }
                    default:
                        break;
                }
            },
            cancelEditDetail: function(section, index) {
                switch (section) {
                    case 1:
                        this.contactsDetail = this.originContactData;
                        this.isEdit_1 = false;
                        this.isContactReadonly = true;
                        this.showContactEditBtn = true;
                        break;
                    case 2:
                        this.contactsOrgs = this.originContactOrgData;
                        this.$set(this.isEdit_2, index, false);
                        // this.isEdit_2[index] = false;
                        this.isCompanyReadonly[index] = true;
                        this.showCompanyEditBtn[index] = true;
                        break;
                    default:
                        break;
                }
            },
            async confirmEditDetail(isContact){
                this.formRule.phoneRules.push(() => {return (this.isNumVaild || i18n.t('CONTACT_DETAIL.ERROR_3'))});
                if(this.validateForm()){
                    await (this.loading_contactDetail = true);
                    await (this.isContactReadonly = true);
                    delete this.contactsDetail.image;
                    delete this.contactsDetail.owner.usersetting.photo;

                    if (isContact) {
                        this.editOrg = this.contactsDetail;
                    }
                    await ContactDataService.editContactsDetail(this.contactsDetail.id, this.editOrg, isContact)
                    .then(response => {
                        if(isContact){
                            this.isEdit_1 = false;
                            this.showContactEditBtn = true;
                            // this.dataCheck = [this.contactsDetail.title,this.contactsDetail.department,this.contactsDetail.level];
                            this.levelName = this.getLevelName();
                            // if(this.check[0] != this.dataCheck[0])
                            // {
                            //     this.changeText = i18n.t('CONTACT_DETAIL.TIMELINE_TITLE')+this.contactsDetail.title
                            //     this.getFieldHistoryData(this.changeText);
                            //     this.timeLines.unshift(this.fieldhistoryData);
                            // }
                            // if (this.check[1] != this.dataCheck[1])
                            // {
                            //     this.changeText = i18n.t('CONTACT_DETAIL.TIMELINE_DEPARTMENT')+this.contactsDetail.department
                            //     this.getFieldHistoryData(this.changeText)
                            //     this.timeLines.unshift(this.fieldhistoryData);
                            // } 
                            // if(this.check[2] != this.dataCheck[2])
                            // {      
                            //     this.changeText = i18n.t('CONTACT_DETAIL.TIMELINE_LEVEL')+this.levelList[this.contactsDetail.level].name
                            //     this.getFieldHistoryData(this.changeText)
                            //     this.timeLines.unshift(this.fieldhistoryData);
                            // }
                            // this.check = this.dataCheck
                            this.refreshTimeLine();
                            
                        }
                        this.contactsDetail.owner.usersetting.photo = response.data.owner.usersetting.photo;
                        this.set_title();
                        // if is CIC tab
                        try{
                            window.parent.postMessage({'new_tab_name':this.set_title()}, '*');
                        }
                        // eslint-disable-next-line
                        catch{}
                    })
                    .catch(response =>{
                        this.showErrorDialog(response);
                    });
                    this.editOrg = {};
                    await (this.loading_contactDetail = false);
                    this.formRule.phoneRules = [];
                }
            },
            async confirmEditContactOrg(index, data) {
                if(!this.$refs.formOrg.validate()){
                    return;
                }
                await this.$set(this.loading_OrgDetail, index, true);
                let newOrg = {id: "-1", name: this.comboText, company_number: "0"};
                let editContactOrgData = {
                    contact_id: this.contactsDetail.id,
                    id: data.id,
                    position: data.position == "" ? null : data.position,
                    juristic_person_name: data.juristic_person_name == "" ? null : data.juristic_person_name,
                    shareholding: data.shareholding == "" ? null : data.shareholding,
                    organization: {
                        id: data.organization.id,
                        name: data.organization.name,
                        company_number: data.organization.company_number
                    }
                }
                if (this.comboText != '' && this.comboText != null) {
                    if (this.contactsOrgs[index].organization.name != this.comboText) {
                        editContactOrgData.organization = newOrg; // 無資料的公司
                    }
                }
                else {
                    this.showErrorDialog(i18n.t('CONTACT_DETAIL.ERROR_4'));
                    return;
                }

                // 判斷公司有無重複
                let orgIdList = [];
                let orgNameList = [];
                this.contactsOrgs.forEach(org => {
                    orgIdList.push(org.organization.id);
                });
                orgIdList.splice(this.orgIndex, 1);
                this.contactsOrgs.forEach(org => {
                    orgNameList.push(org.organization.name);
                });
                orgNameList.splice(this.orgIndex, 1);
                var indexId = orgIdList.indexOf(editContactOrgData.organization.id);
                var indexName = orgNameList.indexOf(editContactOrgData.organization.name);

                if (indexId != -1 || indexName != -1) {
                    this.showErrorDialog(i18n.t('CONTACT_DETAIL.ERROR_5'));
                    return;
                } else {
                    await ContactDataService.editContactOrg(editContactOrgData)
                    .then(response => {
                        if (editContactOrgData.organization.id == 0) {
                            let principalData = {
                                "organization_id": response.data.organization.id,
                                "owner_id": this.contactsDetail.owner.id
                            };
                            ContactDataService.createCompanyPrincipal(principalData);
                        }
                        this.contactsOrgs[this.orgIndex] = response.data;
                    });
                }
                if (index == 0) {
                    this.head_org = this.contactsOrgs[0].organization;
                }
                await this.$set(this.isEdit_2, index, false);
                await this.$set(this.loading_OrgDetail, index, false);
                await this.$set(this.isCompanyReadonly, index, true);
                await this.$set(this.showCompanyEditBtn, index, true);
            },
            // About timeLines
            arrangeTimeLine(timelineList){
                let havePinned = timelineList.filter(this.pinnedTrue);
                let noPinned = timelineList.filter(this.pinnedFalse);
                havePinned = havePinned.sort(function(a, b){
                    let aTime = new Date(a.time+"Z");
                    let bTime = new Date(b.time+"Z");
                    return bTime-aTime });
                noPinned = noPinned.sort(function(a, b){
                    let aTime = new Date(a.time+"Z");
                    let bTime = new Date(b.time+"Z");
                    return bTime-aTime });
                return havePinned.concat(noPinned);
            },
            onPinning() {
                this.pinning = false;
            },
            pinnedTrue(obj){
                if ("pinned" in obj && obj.pinned == true)
                    return true;
            },
            pinnedFalse(obj){
                if (!("pinned" in obj))
                    return true;
                else if (obj.pinned == false)
                    return true;
            },
            getTimeLineIcon: function(type){
                let icon = "";
                switch (type) {
                    case "EMAIL":
                        icon = 'icon-mail';
                        break;
                    case "NOTE":
                        icon = 'icon-note';
                        break;
                    case "FIELDHISTORY":
                        icon = 'mdi-history';
                        break;
                    case "SMS":
                        icon = 'mdi-message-text';
                        break;
                    case "UPCHAT":
                        icon = 'mdi-message-text-outline';
                        break;
                    case "ACTIVITY_FIELDHISTORY":
                        icon = 'icon-calendar';
                        break;
                    case "DUE_ACTIVITY":
                        icon = 'mdi-bell-ring';
                        break;
                    default:
                        icon = 'mdi-help-circle';
                        break;
                }
                return icon;
            },
            getNoteData(){
                let userJson = window.localStorage.getItem('user');
                let user = JSON.parse(userJson);
                this.noteData = {};
                this.noteData.contact = this.contactsDetail.id;
                this.noteData.owner = `${user.last_name} ${user.first_name}`;
            },
            getEachMessageFormatTime(data){
                let date = data
                let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
                let today  = new Date(date);
                if(this.$i18n.locale == "zh_TW")
                {
                   return today.toLocaleDateString("zh-TW", options); 
                }else
                {
                    return today.toLocaleDateString("en-US", options);
                }                
            },
            getFormatTime(data){
                let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
                let today  = data;
                if(this.$i18n.locale == "zh_TW")
                {
                   return today.toLocaleDateString("zh-TW", options); 
                }else
                {
                    return today.toLocaleDateString("en-US", options);
                }                
            },
            getLittleFormatTime(data){                
                let today  = data;
                if(this.$i18n.locale == "zh_TW")
                {
                   return today.toLocaleDateString("zh-TW"); 
                }else
                {
                    return today.toLocaleDateString("en-US");
                }                 
            },
            getFieldHistoryData(data){
                let userJson = window.localStorage.getItem('user');
                let user = JSON.parse(userJson);
                this.fieldhistoryData = {};
                this.fieldhistoryData.type = "FIELDHISTORY";
                this.fieldhistoryData.timeConvert = this.getFormatTime(new Date());
                this.fieldhistoryData.content = data;                
                this.fieldhistoryData.contact = this.contactsDetail.id;
                this.fieldhistoryData.changed_by = `${user.last_name} ${user.first_name}`;
            },
            getPinnData(obj){
                obj.pinned = obj.pinned ? false : true;
                let data = {};
                data.pinned = JSON.parse(JSON.stringify(obj.pinned));
                data.id = JSON.parse(JSON.stringify(obj.id));
                return data;
            },
            deleteNoteFrontEnd(id){
                // delete note in time line
                let item = this.timeLines.find(e => e.id == id);
                let index = this.timeLines.indexOf(item);
                this.timeLines.splice(index, 1);
                // delete note in alert
                let item_alert = this.alertNote.find(e => e.id == id);
                let index_alert = this.alertNote.indexOf(item_alert);
                this.alertNote.splice(index_alert, 1);
            },
            getTabName(key){
                if(key == 'note')
                    this.isNoteTab = true;
                else
                    this.isNoteTab = false;
                if(key == 'documents')
                    this.isdocumentTab = true;
                else
                    this.isdocumentTab = false;
                if(key == 'activities')
                    this.isActivityTab = true;
                else
                    this.isActivityTab = false;
            },
            hasType(arr, type){
                let answer = arr.some(function(item){
                    return item.type == type;
                }) 
                return answer;
            },
            // Dialog
            async onChangeOwn(data) {
                await (this.otherData.contact_id = data.id);
                await (this.otherData.last_name = data.last_name);
                await (this.changeOwnDialog = true);
            },
            onAddNote(){
                this.getNoteData();
                this.addNoteDialog = true; 
            },
            onEditNote(item){
                this.noteData = item;
                this.editNoteDialog = true;
            },
            onDeleteNote(id){
                this.showMessageDialog(i18n.t('CONTACT_DETAIL.MESSAGE'));
                this.noteIdOnDelete = id;
                this.delMode = 'delNote';
            },
            onPinNote(item){
                NoteDataService.editNote(this.getPinnData(item))
                .then(() =>{
                    this.refreshTimeLine();
                    if (item.pinned == true) {
                        this.pinning = true;
                    }
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
            },
            onDeleteDeal(item){
                this.delDealData = item;
                this.delMode = 'delDeal';
                this.showMessageDialog(i18n.t('CONTACT_DETAIL.MESSAGE_2'));
            },
            onEmailOppties(oppties){
                this.opptyData = oppties[0];
                this.toDealDialog = true;
            },
            onEmailAttachment(attachments){
                this.emailAttachmentData = attachments;
                this.emailAttachmentsDialog = true;
            },
            onEmailContact(contacts){
                this.emailContactData = contacts;
                this.emailContactsDialog = true;
            },
            onEmitEmailAttachmentsDialog(){
                this.emailAttachmentsDialog = false;
            },
            onEmitEmailContactsDialog(){
                this.emailContactsDialog = false;
            },
            onEmitEmailOpptiesDialog(){
                this.emailOpptiesDialog = false
            },
            async onEmitChangeOwnDialog(val) {
                this.changeOwnDialog = false;
                if (val) {
                    this.succMode = "changeOwn";
                    await this.showSuccessDialog();
                }
            },
            onEmitAddNoteDialog(val){
                this.addNoteDialog = false;
                if (val) {
                    if (this.noteData.content.length > 100) {
                        this.$set(this.noteData, 'isMore_all', true);
                        this.$set(this.noteData, 'isMore_note', true);
                    } else {
                        this.$set(this.noteData, 'isMore_all', false);
                        this.$set(this.noteData, 'isMore_note', false);
                    }
                    if (this.noteData.content.length > 300) {
                        this.$set(this.noteData, 'isMore_detail', true);
                    } else {
                        this.$set(this.noteData, 'isMore_detail', false);
                    }
                    this.timeLines.unshift(this.noteData);
                    this.timeLines = this.arrangeTimeLine(this.timeLines);
                    // this.showSuccessDialog();
                }
            },
            onEmitAddNoteData(data) {
                let index = this.timeLines.findIndex(item => {return item.id == data.id})
                this.timeLines[index].time = data.created_on;
                this.refreshTimeLine();
            },
            onEmitEditNoteDialog(val){
                this.editNoteDialog = false;
                if (val) {
                    this.refreshTimeLine();
                    if (this.noteData.content.length > 100) {
                        this.$set(this.noteData, 'isMore_all', true);
                        this.$set(this.noteData, 'isMore_note', true);
                    } else {
                        this.$set(this.noteData, 'isMore_all', false);
                        this.$set(this.noteData, 'isMore_note', false);
                    }
                    if (this.noteData.content.length > 300) {
                        this.$set(this.noteData, 'isMore_detail', true);
                    } else {
                        this.$set(this.noteData, 'isMore_detail', false);
                    }
                    // this.showSuccessDialog();
                }
                this.noteIndex = 0;
            },
            onEditAactivity() {
                this.activityDialog = true;
            },
            onEmitActivityDialog(val) {
                this.activityDialog = false;
                if (val) {
                    this.showSuccessDialog();
                }
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            async onEmitSuccessDialog() {
                this.successDialog = false;
                if (this.succMode == "changeOwn") {
                    this.changeText = i18n.t('CONTACT_DETAIL.TIMELINE_OWNER')+`${this.changeOwnData.last_name} ${this.changeOwnData.first_name}`;
                    await this.refreshTimeLine();
                }
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            async onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val && this.delMode == 'delContact') {
                    await ContactDataService.delete(this.contactsDetail.id)
                    .then(response => {
                        if (response.data.error == "Have Opportunity!") {
                            if(response.data.errorlist != undefined){
                                let name = "";
                                for(let i = 0; i<response.data.errorlist.length; i++){
                                    if(i == response.data.errorlist.length - 1)
                                        name += `${response.data.errorlist[i]}`;
                                    else
                                        name += `${response.data.errorlist[i]}、`;
                                }
                                this.showErrorDialog(i18n.t('CONTACT_DETAIL.ERROR_6_1')+` ${name} "` +i18n.t('CONTACT_DETAIL.ERROR_6_2'));
                            }else{
                                this.showErrorDialog(i18n.t('CONTACT_DETAIL.ERROR_7'));
                            }
                            
                        } else {
                            this.showSuccessDialog();
                            setTimeout(() => {this.$router.replace('/Contacts');}, 1000)
                        }
                    })
                    .catch(response => {
                        this.showErrorDialog(response)
                    });
                }
                if (val && this.delMode == 'delContactOrg') {
                    let orgContactId = this.contactsOrgs[this.orgIndex].id
                    await OrganizationDataService.maincontactdel(orgContactId)
                    .then(() => {
                        this.contactsOrgs.splice(this.orgIndex, 1);
                        if (this.contactsOrgs != 0) {
                            this.head_org = this.contactsOrgs[0].organization;
                        }
                        else {
                            this.head_org = '';
                            this.comboText = '';
                        }
                        this.showSuccessDialog();
                        this.orgIndex = 0;
                    })
                    .catch(response => {
                        this.showErrorDialog(response)
                    });
                }
                if (val && this.delMode == 'delNote'){
                    await NoteDataService.deleteNote(this.noteIdOnDelete)
                    .then(() => {
                        this.deleteNoteFrontEnd(this.noteIdOnDelete);
                        this.showSuccessDialog();
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    });
                }
                if (val && this.delMode == 'delDeal'){
                     
                    await OpportunityDataService.delete(this.delDealData.id)
                    .then(() => {
                        let deleteIndex_allOppty = this.getIndex(this.opportunities, this.delDealData);
                        this.opportunities.splice(deleteIndex_allOppty, 1);

                        if(this.delDealData.closed_reason < 1 || this.delDealData.closed_reason == null && this.delDealData.closed_reason == undefined || this.delDealData.closed_reason == " "){
                            let deleteIndex_openOppty = this.getIndex(this.openOppties, this.delDealData)
                            this.openOppties.splice(deleteIndex_openOppty, 1);
                        }
                    })
                    this.successDialog = true; 
                    this.delDealData = {};
                }

                this.noteIdOnDelete == null;
                this.delMode = '';
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            onDelete () {
                this.delMode = 'delContact';
                this.showMessageDialog(i18n.t('CONTACT_DETAIL.MESSAGE_3'));
            },
            onCopy() {
                this.showMessageDialog(i18n.t('CONTACT_DETAIL.MESSAGE_4'));
            },
            showAddDealDialog() {
                this.addDealData = {
                    name: null,
                    primary_contact: {
                        id: this.contactsDetail.id,
                        full_name: this.contactsDetail.first_name ? this.contactsDetail.last_name + ' ' + this.contactsDetail.first_name : this.contactsDetail.last_name
                    },
                    oppty_org: null,
                    stage_type: null,
                    amount: null,
                    expected_close_date: null,
                    forecast_category: 1,
                }
                this.addDealDialog = true;
            },
            onEmitAddDealDialog(val) {
                this.addDealDialog = false;
                if (val) {
                    this.showSuccessDialog();
                }
            },
            onEmitNewDealData(data) {
                
                if (data.oppty_contact != null) {
                    if (this.contactsDetail.id == data.oppty_contact.id) {
                        this.opportunities.unshift(data);
                        if(data.closed_reason < 1 || data.closed_reason == null || data.closed_reason == undefined || data.closed_reason == " "){
                            this.openOppties.unshift(data);
                        } 
                    }
                } 
                else if(data.primary_contact != null){
                    if (this.contactsDetail.id == data.primary_contact.id) {
                        this.opportunities.unshift(data);
                        if(data.closed_reason < 1 || data.closed_reason == null || data.closed_reason == undefined || data.closed_reason == " "){
                            this.openOppties.unshift(data);
                        } 
                    }
                }
            },
            showAddCaseDialog(){
                this.addCaseData = {
                    subject: '',
                    primary_contact: {
                        id: this.contactsDetail.id,
                        full_name: this.full_name
                    },
                    case_org: null,
                    source: '',
                    group: '',
                    type: '',
                    due_date: '',
                    incoming_email:this.contactsDetail.email,
                    incoming_phone:this.contactsDetail.mobile_phone,
                };
                this.addCaseDialog = true;
            },
            onEmitAddCaseDialog(val){
                this.addCaseDialog = false;
                if(val){
                    this.showSuccessDialog();
                }
            },
            onEmitNewCaseData(data) {
                if (this.contactsDetail.id == data.primary_contact.id) {
                    this.openCases.push(data);
                    this.openCasesCount ++;
                }
            },
            async showToDealDialog(item) {
                await (this.opptyData = item);
                await (this.toDealDialog = true);
            },
            onEmitToDealDialog() {
                this.toDealDialog = false;
            },
            async onEmitSendEmailDialog(val) {
                if (val) {
                    this.showSuccessDialog();
                    await this.refreshTimeLine();
                }
            },
            async onEmitSendSmsDialog(val) {
                if (val) {
                    this.showSuccessDialog();
                    await this.refreshTimeLine();
                }
            },
            async getContactTimeline(id) {
                await ContactDataService.getContactTimeline(id)
                .then(response => {
                    // this.timeLines = this.arrangeTimeLine(response.data);
                    this.timeLines = response.data
                    let i = 0;
                    this.alertNote = [];
                    this.timeLines.forEach(item => {
                        // item.timeConvert = this.getFormatTime(new Date(item.time+"Z"))
                        item.timeConvert = this.getFormatTime(new Date(item.time))
                        if(item.type == "NOTE" && item.pinned ==true){
                            item.alertTime = this.getLittleFormatTime(new Date(item.time))
                            let note = this.alertNote.filter(e => e.id == item.id);
                            note.length == 0 ? this.alertNote.push(item) : '';
                        }
                        if (item.type == "NOTE") {
                            if (item.content.length > 100) {
                                this.$set(this.timeLines[i], 'isMore_all', true);
                                this.$set(this.timeLines[i], 'isMore_note', true);
                            } else {
                                this.$set(this.timeLines[i], 'isMore_all', false);
                                this.$set(this.timeLines[i], 'isMore_note', false);
                            }
                            if (item.content.length > 300) {
                                this.$set(this.timeLines[i], 'isMore_detail', true);
                            } else {
                                this.$set(this.timeLines[i], 'isMore_detail', false);
                            }
                        } 
                        if(item.type == "EMAIL"){
                            let to_list = [];
                            let cc_list = [];
                            let sender_name = "";
                            try{
                                sender_name = this.user_list.filter(el => el.email == item.sender)[0].fullName;
                            }
                            catch{
                                try{
                                    sender_name = item.contacts.filter(el => el.email == item.sender)[0].__chineseName__;
                                }
                                catch{
                                    sender_name = item.sender;
                                }
                            }
                            item.sender_data = {'sender_name':sender_name,'email':item.sender};
                            for(let i in item.recipient.to){
                                let name = "";
                                try{
                                    name = item.contacts.filter(el => el.email == item.recipient.to[i])[0].__chineseName__;
                                }
                                catch{
                                    name = "";
                                }
                                to_list.push({'name':name,'email':item.recipient.to[i]});
                            }
                            for(let i in item.recipient.cc){
                                let name = "";
                                try{
                                    name = item.contacts.filter(el => el.email == item.recipient.cc[i])[0].__chineseName__;
                                }
                                catch{
                                    name = "";
                                }
                                cc_list.push({'name':name,'email':item.recipient.cc[i]});
                            }
                            item.cc_list = cc_list;
                            item.to_list = to_list;
                        }
                        // else if (item.type == "SMS") {
                        //     if (item.sms_body.length > 100) {
                        //         this.$set(this.timeLines[i], 'isMore_all', true);
                        //     } else {
                        //         this.$set(this.timeLines[i], 'isMore_all', false);
                        //     }
                        //     if (item.sms_body.length > 300) {
                        //         this.$set(this.timeLines[i], 'isMore_detail', true);
                        //     } else {
                        //         this.$set(this.timeLines[i], 'isMore_detail', false);
                        //     }
                        // }
                        i ++;
                    });
                })
                .catch(response => {
                    this.showErrorDialog(response);
                });
                // await UpChatDataService.getContactMessage(this.contactsDetail.last_name,this.contactsDetail.first_name,this.contactsDetail.email,this.contactsDetail.mobile_phone)
                await UpChatDataService.getContactMessage(id)
                        .then((response)=>{
                            this.chat_data = response.data;
                            for (let i in this.chat_data){
                                this.chat_data[i].messages = JSON.parse(this.chat_data[i].messages);
                                this.chat_data[i].assignee = JSON.parse(this.chat_data[i].assignee);
                                if(this.chat_data[i].assignee == null){
                                    this.chat_data[i].assignee = {name: i18n.t('UPCHAT.NO_AGENT')};
                                }
                                this.chat_data[i].timeConvert = this.getFormatTime(new Date(this.chat_data[i].updated_on));
                                this.chat_data[i].time = this.chat_data[i].updated_on;
                                this.chat_data[i].type = "UPCHAT";
                                for (let m in this.chat_data[i].messages){
                                    this.chat_data[i].messages[m].msg_time = this.getEachMessageFormatTime(new Date(this.chat_data[i].messages[m].created_at)*1000);
                                }
                                this.timeLines.push(this.chat_data[i]);
                            }
                            this.chat_data = this.chat_data.sort((a,b)=> new Date(b.time) - new Date(a.time));
                        })
                        .catch(() => {
                            this.chat_data = null;
                    })
                this.originTimeLines = JSON.parse(JSON.stringify(this.timeLines));
                await (this.chat_data = this.chat_data.sort((a,b)=> new Date(b.time) - new Date(a.time)));
                await (this.timeLines = this.timeLines.sort((a,b)=> new Date(b.time) - new Date(a.time)));
                await (this.timeLines = this.arrangeTimeLine(this.timeLines));
            },
            showAddContactOrg() {
                this.newContactOrgData = {
                    contact_id: this.contactsDetail.id,
                    organization: '',
                    position: '',
                    juristic_person_name: '',
                    shareholding: '',
                    orgIdList: [],
                    orgNameList: [],
                    owner: this.contactsDetail.owner
                }
                this.contactsOrgs.forEach(org => {
                    this.newContactOrgData.orgIdList.push(org.organization.id);
                });
                this.contactsOrgs.forEach(org => {
                    this.newContactOrgData.orgNameList.push(org.organization.name);
                });
                this.addContactOrg = true;
            },
            onEmitAddContactOrgDialog(val) {
                if (val) {
                    this.showSuccessDialog();
                }
                this.addContactOrg = false;
            },
            onEmitAddContactOrgData(data) {
                this.contactsOrgs.push(data);
                this.head_org = this.contactsOrgs[0].organization;
                this.isEdit_2.push(false);
                this.isCompanyReadonly.push(true);
                this.showCompanyEditBtn.push(true);
            },
            // Dialog Area ----------------------------------------- [End]
            showEmailDetail(event){
                let n = event.target.closest('div .no-gutters').previousSibling.lastChild;
                if (n.classList.contains('d-none')){
                    n.classList.remove('d-none');
                    event.target.innerText = i18n.t('GENERAL.HIDE_CONTENT');
                }
                else{
                    n.classList.add('d-none');
                    event.target.innerText = i18n.t('GENERAL.SHOW_CONTENT');
                }                       
            },
            moreContent(event){
                let n = event.target.closest('div').previousSibling.firstChild;

                if (n.classList.contains('show-more')){
                    n.classList.remove('show-more');
                    event.target.innerText = 'MORE';
                }
                else{
                    n.classList.add('show-more');
                    event.target.innerText = 'LESS';
                }   
            },
            isMore(index, place) {
                if (place == "DETAIL") {
                    this.timeLines[index].isMore_detail = this.timeLines[index].isMore_detail ? false : true;
                } else if (place == "ALL") {
                    this.timeLines[index].isMore_all = this.timeLines[index].isMore_all ? false : true;
                } else if (place == "NOTE") {
                    this.timeLines[index].isMore_note = this.timeLines[index].isMore_note ? false : true;
                }
            },
            onDeleteOrg (index) {
                this.orgIndex = index;
                if (this.contactsOrgs != '') {
                    this.showMessageDialog(i18n.t('CONTACT_DETAIL.MESSAGE_5'));
                    this.delMode = 'delContactOrg';
                }
                else {
                    this.showErrorDialog(i18n.t('CONTACT_DETAIL.ERROR_8'));
                }
            },
            getLevelName(){
                if (this.contactsDetail.level == null) {
                    return;
                } else {
                    const levelObj = this.levelList.find(e => e.key == this.contactsDetail.level);
                    return levelObj.name;
                }
            },
            getLoginUserEmail() {
                O365Service.getO365Accounts()
                .then(response => {
                    this.userEmail = response.data[0]? response.data[0].o365_user_principal_name:null;
                });
                // var user = window.localStorage.getItem('user');
                // if (user != null && user != undefined) {
                //     let obj = JSON.parse(user);
                //     return obj.email;
                // } else {
                //     return '';
                // }
            },
            moreLess(value, place) {
                if (value != null) {
                    if (place == "DETAIL") {
                        return value.substring(0, 300) + " ...";
                    } else {
                        return value.substring(0, 100) + " ...";
                    }
                }
            },
            setPosition: function(data) {
                if(data==0)	{
                    return {
                        top: '0px'				
                    }
                }
                else{
                    if(this.getfolder == false){ 
                    return {
                            top : data*45+'px'
                        }
                    }
                    else{
                        return {
                            display : 'none'
                        }
                    }
                }
            },
            folder(data){			
                if (this.getfolder == false){
                this.getfolder = true;
                this.setPosition(data);
                }
                else {
                this.getfolder = false;
                this.setPosition(data);
                }
            },
            onPhoneCheck(event) {
                this.vue_tel = event;
                if (this.vue_tel.isValid == true) {
                    this.isNumVaild = true;
                }
            },
            onPhoneInput() {
                if (this.isNumVaild == true) {
                    if (this.contactsDetail.mobile_phone != this.vue_tel.number.input && this.contactsDetail.mobile_phone != "") {
                        this.isNumVaild = false;
                    }
                } else {
                    if (this.contactsDetail.mobile_phone == "") {
                        this.isNumVaild = true;
                    }
                }
            },
            get_upchat_channel(data){
                let channel_str = data.toLowerCase()
                let channel_name = ''
                switch(true){
                    case channel_str.includes('facebook'):
                        return "Facebook"
                    case channel_str.includes('line'):
                        return "Line"
                    case channel_str.includes('webchat'):
                        return "Webchat"
                    case channel_str.includes('whatsapp'):
                        return "WhatsApp"
                    case channel_str.includes('sms'):
                        return "SMS"
                    default:
                        if(data.split('::').length>1){
                            channel_name = data.split('::')[1];
                        }else{
                            channel_name = 'Web';
                        }
                        return channel_name
                }
            },
            get_upchat_channel_iocn(data){
                let channel_str = data.toLowerCase()
                switch(true){
                    case channel_str.includes('facebook'):
                        return {icon:'mdi-facebook',color:'blue'}
                    case channel_str.includes('line'):
                        return {icon:'fab fa-line',color:'green'}
                    case channel_str.includes('webchat'):
                        return {icon:'mdi-earth',color:'blue'}
                    case channel_str.includes('whatsapp'):
                        return {icon:'mdi-whatsapp',color:'green'}
                    case channel_str.includes('sms'):
                        return {icon:'mdi-message-text-outline',color:'blue'}
                    default:
                        return {icon:'mdi-earth',color:'blue'}
                }
            },
            showUpchatDialog(data){
                this.chatMessage = data;
                this.upchatDialog = true;
            },
            onEmitUpchatDialog(){
                this.upchatDialog = false;
            
            },
            // documnet
            onAddDocument(){
                this.isAddDoucment = true;
            },
            async onDelDocument(){
                this.openDelDocumentDialog = true;
            },
            onEmitDocDialog(){
                this.isAddDoucment = false;
                this.openDelDocumentDialog = false;
            },
            selectDocumentsList(data){
                if(data.length > 0)
                {
                    this.isDelDocument = true;
                }
                else{
                    this.isDelDocument = false;
                }
            },
            async onEmitRefresh(){
                await ContactDataService.getContactsDetail(this.target_id)
                .then(response => {
                    this.contactsDetail = response.data;
                });
                await this.refreshTimeLine();
            },
            async getActivityTimeline(){
                await ActivityDataService.getActivityTimeline(this.target_id,"contact")
                .then((response)=>{
                    this.activityTimeline = this.arrangeTimeLine(response.data.timelineList);
                    this.activityTimeline.forEach(item => {
                        item.timeConvert = this.getFormatTime(new Date(item.time));
                        item.content = item.content.replace("所有者","負責人");
                        item.content_list = item.content.split(item.activity_subject);
                    })
                    this.due_activity_list = response.data.due_list;
                    this.timeLines = this.arrangeTimeLine(this.originTimeLines.concat(this.activityTimeline));
                    for( let i in this.due_activity_list){
                        let obj={
                            "id": this.due_activity_list[i].id,
                            "type":"DUE_ACTIVITY",
                            "subject": this.due_activity_list[i].subject,
                            "type_name": this.due_activity_list[i].type,
                            "is_due":this.due_activity_list[i].is_due
                        }
                        this.timeLines.unshift(obj);
                    }
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
            },
            onAddActivity(){
                this.isAddActivity = true;
            },
            async onEmitAddActivity(val){
                this.isAddActivity = false;
                if(val=="refresh"){
                    await this.getActivityTimeline();
                    await this.showTimlinesCheck();
                }
            },
            async refreshTimeLine(){
                await this.getContactTimeline(this.target_id);
                await this.getActivityTimeline();
                await this.showTimlinesCheck();
            },
        },
        computed: {
            isNew: function(){
                if(this.contactsOrgs[this.orgIndex].organization == ""){
                    return false;
                }
                if(typeof (this.contactsOrgs[this.orgIndex].organization) === 'object'){
                    if(this.contactsOrgs[this.orgIndex].organization.id == 0){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else{
                    return true;
                }
            },
        },
        watch: {
            async search(val) {
                var re = /^(?!\s*$).+/;
                var check = re.test(val);
                var checkLength = /^.{0,200}$/.test(val);
                this.comboText = "";
                if(check && val != null && checkLength){
                    let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                    this.comboText = val;
                    if(!this.comboBoxIsLoading && charNum>=2 && charNum==val.length ){
                        this.comboBoxIsLoading = true;
                        await OrganizationDataService.findOrganizationByNamefromDB(val)
                        .then((response) => {
                            this.organizationList = response.data;
                            let num = 0
                            this.organizationList.forEach((item)=>{
                            item.uqNumber = num
                            num ++
                            })
                        })
                        .finally(() => {
                            this.comboBoxIsLoading = false;
                        });
                    }
                    else {
                        this.comboBoxIsLoading = false;                        
                    }
                }
            },
            isContactReadonly(val){
                this.removeMenu(val);
            }
        },
    }
</script>
<style>
    .country-code div {
        padding-top: 0;
        margin-top: 0;
    }

    .vue-tel-input-vuetify div {
        padding-top: 0;
        margin-top: 0;
    }

    .hide-scrollbar textarea {
        overflow-y: hidden;
    } 
    
</style>
